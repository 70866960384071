import * as React from 'react';

import { ListGroup, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import CirculationItem from '../Common/CirculationItem';

import { LocalitiesListItemProps } from '../../@types/LocalitiesList.d';

/**
 * Component to show a locality item inside a list of localities.
 *
 * @param props
 */
const LocalitiesListItem: React.FC<LocalitiesListItemProps> = (
  props: LocalitiesListItemProps
) => {
  const { locality, setLocalitySelection, weekpart } = props;

  /**
   * On change action to select or deselect a locality (include/exclude)
   * of an area for the current selection.
   *
   * @param event
   */
  const onChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation();
    locality.selected = event.currentTarget.checked;

    setLocalitySelection(locality);
  };

  return (
    <ListGroup.Item>
      <Row className="no-gutters">
        <Col sm={1} className="align-self-center">
          {locality.selectable ? (
            <Form.Check
              id={`select-locality${locality.localityKey}`}
              custom
              type="checkbox"
              onChange={onChangeCheckbox}
              checked={locality.selected}
              label=""
              disabled={!locality.selectable}
            />
          ) : (
            <div key={locality.id} className="icon-warn">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
          )}
        </Col>
        <Col sm={6} className="align-self-center text-eliptize">
          {locality.locality}
        </Col>
        <Col sm={5} className="align-self-center">
          <CirculationItem
            weekpart={weekpart}
            circulation={locality.circulation}
          />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default LocalitiesListItem;
