import * as React from 'react';

import { FormControl, Row, ListGroup, Col } from 'react-bootstrap';

import { MAP_BUTTON_SEARCH_PLACEHOLDER } from '../../constants/labels';

import { searchAddress } from '../../util/api';

import {
  AddressSearchFieldProps,
  AddressSearchFieldState,
} from '../../@types/MapButtons.d';
import { Address } from '../../@types/Modal.d';

/**
 * Component to search for an address or place via an api call.
 */
export default class AddressSearchField extends React.Component<
  AddressSearchFieldProps,
  AddressSearchFieldState
> {
  constructor(props: AddressSearchFieldProps) {
    super(props);

    this.state = {
      searchString: '',
      searchResults: [] as Address[],
      cursorPosition: -1,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onClickListItem = this.onClickListItem.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDownInput = this.onKeyDownInput.bind(this);

    this.searchAddress = this.searchAddress.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  /**
   * Hide the list of search results if the input loses focus
   *
   * @param event
   */
  onBlur(event: React.FocusEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({ searchResults: [] as Address[], cursorPosition: -1 });
  }

  /**
   * On change event for the input. This will trigger a api call to search
   * for the provided string.
   * The call will be sent for each new/removed character as long as the
   * length of the search string is > 2
   *
   * @param event
   */
  onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    const searchString = event.currentTarget.value ?? '';
    this.setState({ searchString, cursorPosition: -1 }, () => {
      this.searchAddress(searchString);
      this.setState({
        searchResults: [] as Address[],
        cursorPosition: -1,
      });
    });
  }

  /**
   * Click action to select one of the returned addresses.
   *
   * @param event
   * @param address
   */
  onClickListItem(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    address: Address
  ): void {
    event.stopPropagation();
    this.selectItem(address);
  }

  /**
   * Key down to navigate throught the search result via arrow keys
   * as well as selection via the enter key.
   *
   * @param event
   * @param address
   */
  onKeyDownInput(event: React.KeyboardEvent<HTMLInputElement>): void {
    event.stopPropagation();
    const { cursorPosition, searchResults } = this.state;

    if (event.keyCode === 38 && cursorPosition > 0) {
      this.setState(prevState => ({
        cursorPosition: prevState.cursorPosition - 1,
      }));
    } else if (
      event.keyCode === 40 &&
      cursorPosition < searchResults.length - 1
    ) {
      this.setState(prevState => ({
        cursorPosition: prevState.cursorPosition + 1,
      }));
    } else if (event.keyCode === 13 && searchResults.length > 0) {
      this.selectItem(
        searchResults[cursorPosition === -1 ? 0 : cursorPosition]
      );
    }
  }

  /**
   * Selects an item. This means the focus of the map will be shifted to
   * the coordinates received by the api call/selection.
   *
   * @param address
   */
  selectItem(address: Address): void {
    const { zoomToAddress } = this.props;
    zoomToAddress(address);
    this.setState({
      searchResults: [] as Address[],
      cursorPosition: -1,
      searchString: address.name,
    });
  }

  /**
   * Triggers an api call to search for the provided address.
   * The results of the api call will be written to the components
   * state.
   *
   * @param searchString
   */
  async searchAddress(searchString: string): Promise<void> {
    if (searchString.length < 2) return;
    const searchResults = (await searchAddress(searchString)) as Address[];

    this.setState({ searchResults, cursorPosition: -1 });
  }

  render(): JSX.Element {
    const { searchResults, cursorPosition, searchString } = this.state;

    return (
      <div className="address-search-container underlay-background">
        <Row className="no-gutters address-search-bar">
          <Col sm={12} className="p-0">
            <FormControl
              className="type-search"
              type="search"
              value={searchString}
              placeholder={MAP_BUTTON_SEARCH_PLACEHOLDER}
              onChange={this.onChange}
              onKeyDown={this.onKeyDownInput}
            />
          </Col>
        </Row>
        <Row className="no-gutters address-results">
          {searchResults.length > 0 && (
            <Col sm={12} className="p-0">
              <ListGroup>
                {searchResults.map((searchResult, i) => (
                  <ListGroup.Item
                    key={searchResult.name}
                    className="selectable search-result-item"
                    active={cursorPosition === i}
                    onClick={(
                      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => this.onClickListItem(event, searchResult)}
                  >
                    {searchResult.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
