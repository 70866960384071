import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import ClientLocationListItem from './ClientLocationListItem';
import ListAddItem from '../Common/ListAddItem';

import { sortClientLocations } from '../../util/sortUtil';
import {
  MODAL_TITLE_ADD_CLIENT_LOCATION,
  CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_LIST_EMPTY,
} from '../../constants/labels';

import { ClientLocationListProps } from '../../@types/ClientLocation.d';

/**
 * Component to display a list of clientLocations.
 *
 * @param props
 */
const ClientLocationList: React.FC<ClientLocationListProps> = (
  props: ClientLocationListProps
) => {
  const {
    planningRestriction,
    clientLocations,
    applyClientLocationDistributionTemplate,
    showNewClientLocationDialog,
    deleteClientLocation,
    changeClientLocationColor,
    zoomToClientLocation,
    setClientLocationSelected,
    showClientLocationDistributionTemplates,
    showClientLocationDistributionTemplateModal,
  } = props;

  return (
    <div className="list-container">
      <ListGroup className="flex-list">
        {clientLocations.length > 0 ? (
          sortClientLocations(clientLocations).map(clientLocation => (
            <ClientLocationListItem
              key={clientLocation.id}
              planningRestriction={planningRestriction}
              clientLocation={clientLocation}
              applyClientLocationDistributionTemplate={
                applyClientLocationDistributionTemplate
              }
              showNewClientLocationDialog={showNewClientLocationDialog}
              deleteClientLocation={deleteClientLocation}
              changeClientLocationColor={changeClientLocationColor}
              zoomToClientLocation={zoomToClientLocation}
              setClientLocationSelected={setClientLocationSelected}
              showClientLocationDistributionTemplates={
                showClientLocationDistributionTemplates
              }
              showClientLocationDistributionTemplateModal={
                showClientLocationDistributionTemplateModal
              }
            />
          ))
        ) : (
          <ListGroup.Item>
            {CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_LIST_EMPTY}
          </ListGroup.Item>
        )}
      </ListGroup>
      {planningRestriction === 'NONE' && (
        <ListAddItem
          showNewModal={showNewClientLocationDialog}
          title={MODAL_TITLE_ADD_CLIENT_LOCATION}
        />
      )}
    </div>
  );
};

export default ClientLocationList;
