/* eslint-disable react/jsx-indent */
import '../../styles/ClientLocationList.scss';

import * as React from 'react';

import ClientLocationList from './ClientLocationList';
import ListHeader from '../Common/ListHeader/ListHeader';

import {
  CLIENT_LOCATION_LIST_SEARCH_FIELD_HINT,
  CLIENT_LOCATION_LIST_TITLE,
} from '../../constants/labels';

import {
  ClientLocationListContainerProps,
  ClientLocationListContainerState,
} from '../../@types/ClientLocation.d';

/**
 * Container component to hold a list of subsidiries.
 */
export default class ClientLocationListContainer extends React.Component<
  ClientLocationListContainerProps,
  ClientLocationListContainerState
> {
  constructor(props: ClientLocationListContainerProps) {
    super(props);

    const { clientLocations } = this.props;
    this.state = {
      filteredClientLocations: clientLocations,
      searchString: '',
    };

    this.filterClientLocationList = this.filterClientLocationList.bind(this);
  }

  /**
   * Used to detect changes in the clientLocation list and possibly refresh
   * the list.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: ClientLocationListContainerProps): void {
    const { clientLocations } = this.props;

    if (
      JSON.stringify(
        clientLocations.map(sub => ({
          ...sub,
          ...{ areas: [] },
          ...{ feature: undefined },
          ...{ layer: undefined },
        }))
      ) !==
      JSON.stringify(
        prevProps.clientLocations.map(sub => ({
          ...sub,
          ...{ areas: [] },
          ...{ feature: undefined },
          ...{ layer: undefined },
        }))
      )
    ) {
      const { searchString } = this.state;
      this.filterClientLocationList(searchString);
    }
  }

  /**
   * Filter the clientLocation list by a given search string.
   *
   * @param searchString
   */
  filterClientLocationList(searchString: string): void {
    const { clientLocations } = this.props;
    let filteredClientLocations;
    if (searchString.length <= 0) filteredClientLocations = clientLocations;
    else {
      const searchStringRegex = searchString
        .split('')
        .map(s => `[${s}]\\w*`)
        .join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredClientLocations = clientLocations.filter(({ name }) =>
        name.match(regex)
      );
    }

    this.setState({ filteredClientLocations, searchString });
  }

  render(): JSX.Element {
    const {
      planningRestriction,
      showClientLocationList,
      applyClientLocationDistributionTemplate,
      hideClientLocationList,
      showNewClientLocationDialog,
      deleteClientLocation,
      changeClientLocationColor,
      zoomToClientLocation,
      setClientLocationSelected,
      showClientLocationDistributionTemplates,
      showClientLocationDistributionTemplateModal,
    } = this.props;
    const { filteredClientLocations, searchString } = this.state;

    return (
      <div
        className={`collapse-content d-flex flex-column ${
          showClientLocationList ? 'shown' : 'hidden'
        } width clientLocation-list-container`}
      >
        {showClientLocationList
          ? [
              <ListHeader
                key={0}
                hideList={hideClientLocationList}
                searchString={searchString}
                filterList={this.filterClientLocationList}
                listTitle={CLIENT_LOCATION_LIST_TITLE}
                searchHint={CLIENT_LOCATION_LIST_SEARCH_FIELD_HINT}
              />,
              <ClientLocationList
                key={1}
                planningRestriction={planningRestriction}
                clientLocations={filteredClientLocations}
                applyClientLocationDistributionTemplate={
                  applyClientLocationDistributionTemplate
                }
                showNewClientLocationDialog={showNewClientLocationDialog}
                deleteClientLocation={deleteClientLocation}
                changeClientLocationColor={changeClientLocationColor}
                zoomToClientLocation={zoomToClientLocation}
                setClientLocationSelected={setClientLocationSelected}
                showClientLocationDistributionTemplates={
                  showClientLocationDistributionTemplates
                }
                showClientLocationDistributionTemplateModal={
                  showClientLocationDistributionTemplateModal
                }
              />,
            ]
          : null}
      </div>
    );
  }
}
