import { differenceBy } from 'lodash';
import { Feature } from 'ol';
import { generateFeatureStyle } from './featureStyle';

// eslint-disable-next-line import/no-cycle
import { getAreasSend } from './exportUtil';
import {
  FEATURE_FIELD_AREA_KEY,
  FEATURE_FIELD_META_INFO,
  LayerTypeCompatibilityMatcher,
} from '../constants/constants';

import { Area, AreaSendFormat, AreaDescription } from '../@types/Area.d';
import {
  AreaDataFormat,
  ClientLocation,
  DistributionTemplateLocation,
  DistributionTemplate,
  ClientLocationDistributionTemplate,
  AreaMetaData,
  Weekpart,
  LayerType,
} from '../@types/Common.d';

/**
 * Get a minimal area object from an area stub.
 *
 * @param areaDescription
 * @param clientLocation
 */
export const getAreaStub = (
  areaDescription: AreaDescription,
  clientLocation?: ClientLocation
): Area => {
  const { areaKey, type, countryCode, weekpart } = areaDescription;

  return {
    areaKey,
    id: -1,
    areaName: '',
    circulation: 0,
    circulationTotal: 0,
    localities: [],
    feature: undefined,
    layer: undefined,
    coverage: 1,
    additionalAreas: [] as Area[],
    clientLocationId: clientLocation?.id,
    weekpart,
    countryCode,
    type,
  } as Area;
};

/**
 * Add an area to an array of area stubs.
 *
 * @param areas
 * @param areaDescription
 * @param clientLocation
 */
export const addAreaStub = (
  areas: Area[],
  areaDescription: AreaDescription,
  clientLocation?: ClientLocation
): number => areas.push(getAreaStub(areaDescription, clientLocation));

/**
 * Add multiple areas to an array of area stubs.
 *
 * @param areas
 * @param areaDescriptions
 * @param clientLocation
 */
export const addAreaStubs = (
  areas: Area[],
  areaDescriptions: AreaDescription[],
  clientLocation?: ClientLocation
): void =>
  areaDescriptions.forEach(areaDescription =>
    addAreaStub(areas, areaDescription, clientLocation)
  );

/**
 * Add area stubs from a distribution template
 *
 * @param areas
 * @param clientLocation
 * @param weekpart
 */
export const getAreaStubsFromTemplate = (
  areas: AreaSendFormat[],
  weekpart: Weekpart,
  clientLocationId?: number
): Area[] =>
  areas.map(area => ({
    areaKey: area.areaKey,
    id: -1,
    areaName: '',
    circulation: 0,
    circulationTotal: 0,
    coverage: 1,
    clientLocationId,
    localities: area.localities.map(locality => ({
      id: locality.id,
      localityKey: locality.localityKey,
      selected: locality.selected,
      administrativeDistrict: '',
      locality: '',
      municipality: '',
      postcode: '',
      areaKey: '',
      selectable: true,
      circulation: 0,
    })),
    feature: undefined,
    layer: undefined,
    weekpart,
    additionalAreas: [] as Area[],
    countryCode: area.countryCode,
    type: area.type,
  }));

/**
 * Get all additional areas from an array of areas
 *
 * @param areas
 */
export const getAllAdditionalAreas = (areas?: Area[]): Area[] => {
  if (areas) return areas.map(area => area.additionalAreas).flat();
  return [] as Area[];
};

/**
 * Get all features from an array of areas
 *
 * @param areas
 */
export const getAllAreaFeatures = (areas?: Area[]): Feature[] => [
  ...(areas?.reduce(
    (acc, area) => (area.feature ? [...acc, ...[area.feature]] : acc),
    [] as Feature[]
  ) ?? []),
  ...getAllAdditionalAreas(areas).reduce(
    (acc, area) => (area.feature ? [...acc, ...[area.feature]] : acc),
    [] as Feature[]
  ),
];

/**
 * Get the difference of additional areas between
 * the previouly selection and the current selection
 *
 * @param oldAreas
 * @param newAreas
 */
export const getAdditionalAreasDifference = (
  oldAreas: Area[],
  newAreas: Area[]
): Area[] =>
  differenceBy(
    getAllAdditionalAreas(oldAreas),
    getAllAdditionalAreas(newAreas),
    'areaKey'
  );

/**
 * Get a representation of an array of areas that
 * the api can understand
 *
 * @param areas
 */
export const getAreaDataFormat = (areas: Area[]): AreaDataFormat[] =>
  areas.map((area: Area) => {
    const { areaKey, type, countryCode } = area;

    return {
      areaKey,
      type,
      countryCode,
    } as AreaDataFormat;
  });

/**
 * Remove all not serializable attributes from an array of areas.
 * This is needed for the communication via iFram messages
 * between map and a overlaying portal like FPP.
 *
 * @param areas
 */
export const removeNotSerializableFromAreas = (areas: Area[]): Area[] =>
  areas.reduce(
    (acc, area) => [
      ...acc,
      ...[
        {
          ...area,
          ...{
            feature: undefined,
            layer: undefined,
            additionalAreas: removeNotSerializableFromAreas(
              area.additionalAreas
            ),
          },
        },
      ],
    ],
    [] as Area[]
  );

/**
 * Remove all not serializable attributes from a clientLocation.
 * This is needed for the communication via iFram messages
 * between map and a overlaying portal like FPP.
 *
 * @param clientLocation
 */
export const removeNotSerializableFromClientLocation = (
  clientLocation: ClientLocation
): ClientLocation => ({
  ...clientLocation,
  ...{ areas: removeNotSerializableFromAreas(clientLocation.areas) },
  ...{ feature: undefined },
  ...{ layer: undefined },
});

/**
 * Remove all not serializable attributes from an array of clientLocations.
 * This is needed for the communication via iFram messages
 * between map and a overlaying portal like FPP.
 *
 * @param clientLocations
 */
export const removeNotSerializableFromClientLocations = (
  clientLocations: ClientLocation[]
): ClientLocation[] =>
  clientLocations.map(clientLocation =>
    removeNotSerializableFromClientLocation(clientLocation)
  );

/**
 * Get the total circulation of an area.
 *
 * @param area
 */
export const calculateAreaCirculation = (area: Area): number =>
  area.localities.reduce((acc, locality) => {
    let rAcc = acc;

    if (
      !locality.selected ||
      typeof locality.circulation === 'string' ||
      locality.circulation <= 0
    )
      return rAcc;

    rAcc += locality.circulation;

    return rAcc;
  }, 0);

/**
 * Get the total circulation of an area including its
 * additional areas
 *
 * @param area
 */
export const calculateAreaCirculationTotal = (area: Area): number =>
  calculateAreaCirculation(area) +
  area.additionalAreas.reduce(
    (acc, addArea) => acc + calculateAreaCirculation(addArea),
    0
  );

/**
 * Calulate the circulation of all areas selected
 * by a given clientLocation.
 *
 * @param clientLocation
 */
export const calculateClientLocationCalculation = (
  clientLocation: ClientLocation
): number =>
  clientLocation.areas.reduce((acc: number, area: Area) => {
    let rAcc = acc;
    rAcc += calculateAreaCirculationTotal(area);

    return rAcc;
  }, 0);

/**
 * Get a new distribution template from the current
 * selection of all clientLocations.
 *
 * @param selectedClientLocations
 * @param distributionTemplateName
 */
export const getDistributionTemplate = (
  selectedClientLocations: ClientLocation[],
  distributionTemplateName: string
): DistributionTemplate =>
  ({
    id: -1,
    name: distributionTemplateName,
    locations: selectedClientLocations.reduce(
      (acc, selectedSusidiary) => [
        ...acc,
        ...[
          {
            locationId: selectedSusidiary.id,
            areas: getAreasSend(selectedSusidiary.areas),
          } as DistributionTemplateLocation,
        ],
      ],
      [] as DistributionTemplateLocation[]
    ),
  } as DistributionTemplate);

/**
 * Get a new clientLocation distribution template
 * from the current selection of a given
 * clientLocation.
 *
 * @param clientLocation
 * @param clientLocationDistributionTemplateName
 */
export const getClientLocationDistributionTemplate = (
  clientLocation: ClientLocation,
  clientLocationDistributionTemplateName: string
): ClientLocationDistributionTemplate =>
  ({
    id: -1,
    locationId: clientLocation.id,
    name: clientLocationDistributionTemplateName,
    areas: getAreasSend(clientLocation.areas),
  } as ClientLocationDistributionTemplate);

/**
 * Insert area meta data and coloring into the
 * feature of an area.
 *
 * @param feature
 * @param areaMetaData
 */
export const applyAreaMetaData = (
  feature?: Feature,
  areaMetaData?: AreaMetaData
): void => {
  if (!feature || !areaMetaData) return;

  const { /* areaKey, */ areaStyle, info } = areaMetaData;

  if (areaStyle)
    feature.setStyle(
      generateFeatureStyle(
        false,
        feature.get(FEATURE_FIELD_AREA_KEY),
        areaStyle
      )
    );

  feature.set(FEATURE_FIELD_META_INFO, info);
};

/**
 * Formats the price to a two digit price string.
 *
 * @param price
 */
export const priceString = (price: string): string =>
  parseFloat(price).toLocaleString('en-de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

/**
 * Check if two given layer types are compatible, means
 * they have the same structure (postcodes, disctricts, ...)
 *
 * @param oldLayerType
 * @param newLayerType
 */
export const checkLayerTypeCompatibility = (
  oldLayerType?: LayerType,
  newLayerType?: LayerType
): boolean =>
  oldLayerType && newLayerType
    ? LayerTypeCompatibilityMatcher[oldLayerType].indexOf(newLayerType) !== -1
    : false;

/**
 * Returns the AreaDescription of an Area object
 *
 * @param area
 */
export const getAreaDescription = (area: Area): AreaDescription => {
  const { areaKey, weekpart, countryCode, type } = area;

  return {
    areaKey,
    weekpart,
    countryCode,
    type,
  };
};

/**
 * Returns the AreaDescription of a list of Area objects
 *
 * @param areas
 */
export const getAreaDescriptions = (areas: Area[]): AreaDescription[] =>
  areas.map(area => getAreaDescription(area));

/**
 * Changes the layer type of an AreaDescription object
 *
 * @param area
 * @param newLayerType
 */
export const changeAreaLayerType = (
  area: AreaDescription,
  newLayerType: LayerType
): AreaDescription => ({ ...area, ...{ type: newLayerType } });

/**
 * Changes the layer type of a list of AreaDescription objects
 *
 * @param areas
 * @param newLayerType
 */
export const changeAreasLayerType = (
  areas: AreaDescription[],
  newLayerType: LayerType
): AreaDescription[] =>
  areas.map(area => changeAreaLayerType(area, newLayerType));
