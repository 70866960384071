import * as React from 'react';

import { Modal, Button, Col } from 'react-bootstrap';

import LocalitiesList from '../LocalitiesList/LocalitiesList';

import {
  MODAL_TITLE_LOCALITIES,
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_ACCEPT,
} from '../../constants/labels';

import { Area, Locality } from '../../@types/Area.d';
import {
  LocalitiesModalProps,
  LocalitiesModalState,
} from '../../@types/Modal.d';

/**
 * Modal dialog to show, select and deselect localities of an area.
 */
export default class LocalitiesModal extends React.Component<
  LocalitiesModalProps,
  LocalitiesModalState
> {
  constructor(props: LocalitiesModalProps) {
    super(props);
    this.state = {
      area: {} as Area,
      selectableLocalities: false,
    };

    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.setArea = this.setArea.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setLocalitySelection = this.setLocalitySelection.bind(this);
  }

  /**
   * Click action to accept the current selection of localities for the area.
   *
   * @param event
   */
  onClickAccept(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    const { confirmLocalitySelection } = this.props;
    const { area } = this.state;

    confirmLocalitySelection(area);
    this.onHide();
  }

  /**
   * Click action to abort the current selection of localities for the area.
   *
   * @param event
   */
  onClickAbort(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();
    this.onHide();
  }

  /**
   * Method to hide and reset the modal dialog.
   */
  onHide(): void {
    const { showModal } = this.props;

    showModal(undefined, false);
  }

  /**
   * Callback method to set a locality selected or deselected.
   *
   * @param locality
   */
  setLocalitySelection(locality: Locality): void {
    const { area } = this.state;
    const localityIndex = area.localities.findIndex(
      sLocality => locality.localityKey === sLocality.localityKey
    );

    if (localityIndex < 0) return;

    area.localities[localityIndex].selected = locality.selected;

    this.setState({ area });
  }

  /**
   * Method to set the area which localities are displayed.
   *
   * @param area
   */
  setArea(area: Area): void {
    const selectableLocalities = area.localities.every(
      locality => locality.selectable
    );

    this.setState({
      selectableLocalities,
      area: { ...area },
    });
  }

  render(): JSX.Element {
    const { show, container, weekpart } = this.props;
    const { selectableLocalities, area } = this.state;

    return (
      <Modal
        show={show}
        centered
        container={container.current}
        onHide={this.onHide}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {MODAL_TITLE_LOCALITIES(area.areaName, area.areaKey)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LocalitiesList
            localities={area.localities}
            weekpart={weekpart}
            setLocalitySelection={this.setLocalitySelection}
          />
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            {selectableLocalities && (
              <Button
                variant="primary"
                className="ci"
                onClick={this.onClickAccept}
              >
                {BUTTON_TITLE_ACCEPT}
              </Button>
            )}
          </Col>
          <Col className="p-0">
            <Button
              variant="primary"
              className="ci"
              onClick={this.onClickAbort}
            >
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
