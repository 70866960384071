import * as React from 'react';

import { Col, Row, ListGroup } from 'react-bootstrap';

import ClientLocationListButtons from './ClientLocationListButtons';

import { getClientLocationSend } from '../../util/exportUtil';

import {
  ClientLocationListItemProps,
  ClientLocationListItemState,
} from '../../@types/ClientLocation.d';

/**
 * Component to display a clientLocation as list item.
 */
export default class ClientLocationListItem extends React.Component<
  ClientLocationListItemProps,
  ClientLocationListItemState
> {
  constructor(props: ClientLocationListItemProps) {
    super(props);

    this.state = {
      showColorPicker: false,
    };

    this.onClickItem = this.onClickItem.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
    this.changeClientLocationColor = this.changeClientLocationColor.bind(this);
  }

  /**
   * Click action to select a clientLocation as new selected clientLocation.
   *
   * @param event
   */
  onClickItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    const {
      planningRestriction,
      clientLocation,
      setClientLocationSelected,
      applyClientLocationDistributionTemplate,
    } = this.props;

    event.stopPropagation();

    if (planningRestriction === 'TEMPLATE') {
      const favTemplate = clientLocation.clientLocationDistributionTemplates.find(
        template => template.defaultTemplate
      );

      if (favTemplate) applyClientLocationDistributionTemplate(favTemplate);

      return;
    }

    setClientLocationSelected(clientLocation);
  }

  /**
   * Method to show the color picker.
   */
  showColorPicker(): void {
    const { showColorPicker } = this.state;
    this.setState({ showColorPicker: !showColorPicker });
  }

  /**
   * Method to set a new clientLocation color.
   *
   * @param color
   */
  changeClientLocationColor(color: string): void {
    const { clientLocation, changeClientLocationColor } = this.props;

    clientLocation.colorSelectedFill = color;
    changeClientLocationColor(getClientLocationSend(clientLocation));
  }

  render(): JSX.Element {
    const {
      planningRestriction,
      clientLocation,
      showNewClientLocationDialog,
      deleteClientLocation,
      zoomToClientLocation,
      showClientLocationDistributionTemplates,
      showClientLocationDistributionTemplateModal,
    } = this.props;
    const { showColorPicker } = this.state;

    return (
      <ListGroup.Item
        onClick={this.onClickItem}
        className="selectable hover-item"
        active={clientLocation.selected}
      >
        <Row className="no-gutters">
          <Col md={12}>{clientLocation.name}</Col>
          <Col md={12}>
            <ClientLocationListButtons
              planningRestriction={planningRestriction}
              clientLocation={clientLocation}
              showColorPicker={showColorPicker}
              showPicker={this.showColorPicker}
              showNewClientLocationDialog={showNewClientLocationDialog}
              deleteClientLocation={deleteClientLocation}
              changeClientLocationColor={this.changeClientLocationColor}
              zoomToClientLocation={zoomToClientLocation}
              showClientLocationDistributionTemplates={
                showClientLocationDistributionTemplates
              }
              showClientLocationDistributionTemplateModal={
                showClientLocationDistributionTemplateModal
              }
            />
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}
