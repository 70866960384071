/**
 * This file holds a number of methods which are used to
 * convert client related data from the api endpoints to
 * a format which can be used in the application.
 *
 */

import moment from 'moment';

import config from '../../config';

import { Area, Locality } from '../../@types/Area.d';
import {
  ClientLocation,
  ColorStyle,
  ClientLayer,
  Product,
  ProductPrice,
  Client,
  DistributionTemplate,
  DistributionTemplateLocation,
  OrderHistoryTemplate,
  OfferHistoryTemplate,
  ClientMetaData,
  AreaMetaData,
  ObjectMap,
  POI,
  ClientLocationDistributionTemplate,
} from '../../@types/Common.d';
import { NO_DATE_SET } from '../../constants/labels';
import { STYLE_ZINDEX_BACK } from '../../constants/constants';
// eslint-disable-next-line import/no-cycle
import { getOpeningHours } from '../exportUtil';

/**
 * Extract a distribution template location (clientLocation and its areas) from a response
 *
 * @param responseDistributionTemplateLocation
 */
export const extractDistributionTemplateLocation = (
  responseDistributionTemplateLocation: any
): DistributionTemplateLocation => {
  const {
    areas,
    locationId,
    locationSrcId,
    id,
  } = responseDistributionTemplateLocation;

  return {
    locationId: locationId ?? locationSrcId ?? id,
    areas: areas?.map(
      (area: Area) =>
        ({
          areaKey: area.areaKey,
          type: area.type,
          countryCode: area.countryCode,
          localities: area.localities?.map(
            locality =>
              ({
                id: locality.id,
                localityKey: locality.localityKey,
                selected: locality.selected,
              } ?? ([] as Locality[]))
          ),
        } ?? ([] as Area[]))
    ),
  } as DistributionTemplateLocation;
};

/**
 * Extract an array of distribution templates locations (clientLocations and their areas) from a response
 *
 * @param responseDistributionTemplateLocations
 */
export const extractDistributionTemplateLocations = (
  responseDistributionTemplateLocations: any
): DistributionTemplateLocation[] =>
  responseDistributionTemplateLocations?.map(
    (responseDistributionTemplateLocation: any) =>
      extractDistributionTemplateLocation(responseDistributionTemplateLocation)
  ) ?? ([] as DistributionTemplateLocation[]);

/**
 * Extract a distribution template from a response
 *
 * @param responseDistributionTemplate
 */
export const extractDistributionTemplate = (
  responseDistributionTemplate: any
): DistributionTemplate => {
  const { id, name, locations } = responseDistributionTemplate;
  return {
    id,
    name,
    locations: extractDistributionTemplateLocations(locations),
  } as DistributionTemplate;
};

/**
 * Extract an array of distribution templates from a response
 *
 * @param responseDistributionTemplates
 */
export const extractDistributionTemplates = (
  responseDistributionTemplates: any
): DistributionTemplate[] =>
  responseDistributionTemplates?.map(
    (responseDistributionTemplate: any) =>
      extractDistributionTemplate(responseDistributionTemplate) ??
      ([] as DistributionTemplate[])
  );

/**
 * Extract a clientLocation distribution template from a response
 *
 * @param responseClientLocationDistributionTemplate
 * @param locationId
 */
export const extractClientLocationDistributionTemplate = (
  responseClientLocationDistributionTemplate: any,
  locationId: number
): ClientLocationDistributionTemplate => {
  const {
    areas,
    id,
    name,
    defaultTemplate,
  } = responseClientLocationDistributionTemplate;
  return {
    locationId,
    id,
    name,
    defaultTemplate,
    areas: areas.map((area: any) => ({
      areaKey: area.areaKey,
      countryCode: area.countryCode,
      type: area.type,
      localities: area.localities.map((locality: any) => ({
        id: locality.id,
        localityKey: locality.localityKey,
        selected: locality.selected,
      })),
    })),
  } as ClientLocationDistributionTemplate;
};

/**
 * Extract an array clientLocation distribution template from a response
 *
 * @param responseClientLocationDistributionTemplates
 * @param locationId
 */
export const extractClientLocationDistributionTemplates = (
  responseClientLocationDistributionTemplates: any,
  locationId: number
): ClientLocationDistributionTemplate[] =>
  responseClientLocationDistributionTemplates?.map(
    (responseClientLocationDistributionTemplate: any) =>
      extractClientLocationDistributionTemplate(
        responseClientLocationDistributionTemplate,
        locationId
      )
  ) ?? ([] as ClientLocationDistributionTemplate[]);

/**
 * Extract an order template (a previously made and ordered distribution)
 * from a response
 *
 * @param responseOrderTemplate
 */
export const extractOrderTemplate = (
  responseOrderTemplate: any
): OrderHistoryTemplate => {
  const { id, actionName, distributionDay, locations } = responseOrderTemplate;

  return {
    id,
    actionName: actionName.length > 0 ? actionName : undefined,
    distributionDay: distributionDay
      ? moment(distributionDay).format('DD.MM.YYYY')
      : NO_DATE_SET,
    locations: extractDistributionTemplateLocations(locations),
  } as OrderHistoryTemplate;
};

/**
 * Extract an array of order templates (previously made and ordered distributions)
 * from a response
 *
 * @param responseOrderTemplates
 */
export const extractOrderTemplates = (
  responseOrderTemplates: any
): OrderHistoryTemplate[] =>
  responseOrderTemplates?.map((responseOrderTemplate: any) =>
    extractOrderTemplate(responseOrderTemplate)
  ) ?? ([] as OrderHistoryTemplate[]);

/**
 * Extract an offer template (a previously made distribution for which an offer
 * was received) from a response
 *
 * @param responseOrderTemplate
 */
export const extractOfferTemplate = (
  responseOrderTemplate: any
): OfferHistoryTemplate => {
  const { id, actionName, distributionDay, locations } = responseOrderTemplate;

  return {
    id,
    actionName: actionName.length > 0 ? actionName : undefined,
    distributionDay: distributionDay
      ? moment(distributionDay).format('DD.MM.YYYY')
      : NO_DATE_SET,
    locations: extractDistributionTemplateLocations(locations),
  } as OfferHistoryTemplate;
};

/**
 * Extract an array of offer templates (previously made distributions for which an offer
 * was received) from a response
 *
 * @param responseOfferTemplates
 */
export const extractOfferTemplates = (
  responseOfferTemplates: any
): OfferHistoryTemplate[] =>
  responseOfferTemplates?.map((responseOfferTemplate: any) =>
    extractOfferTemplate(responseOfferTemplate)
  ) ?? ([] as OfferHistoryTemplate[]);

/**
 * Extracts a client location (clientLocation) from a response
 *
 * @param responseClientLocation
 */
export const extractClientLocation = (
  responseClientLocation: any
): ClientLocation => {
  const {
    addressName,
    city,
    colorSelectedFill,
    email,
    housenumber,
    id,
    lat,
    lon,
    name,
    number,
    web,
    fax,
    openingHours,
    planable,
    phone,
    poi,
    postcode,
    street,
    distributionTemplates,
  } = responseClientLocation;

  return {
    addressName,
    areas: [] as Area[],
    city,
    colorSelectedFill,
    housenumber,
    email,
    id,
    lat,
    lon,
    name,
    number,
    web,
    fax,
    openingHours: getOpeningHours(openingHours),
    planable,
    phone,
    poi: {
      id: poi?.id,
      active: poi?.active,
      inactive: poi?.inactive,
      name: poi?.name,
    } as POI,
    postcode,
    selected: false,
    show: true,
    street,
    clientLocationDistributionTemplates: extractClientLocationDistributionTemplates(
      distributionTemplates,
      id
    ),
  } as ClientLocation;
};

/**
 * Extracts an array of client locations (clientLocations) from a response
 *
 * @param responseClientLocations
 */
export const extractClientLocations = (
  responseClientLocations: any
): ClientLocation[] =>
  responseClientLocations?.map((responseClientLocation: any) =>
    extractClientLocation(responseClientLocation)
  ) ?? ([] as ClientLocation[]);

/**
 * Extracts a client layer (planable areas with configuration) from a response
 *
 * @param responseClientLayer
 */
export const extractClientLayer = (responseClientLayer: any): ClientLayer => {
  const layerColor = {
    fill: responseClientLayer.colorFill,
    fillSelected: responseClientLayer.colorSelectedFill,
    stroke: responseClientLayer.colorStroke,
    strokeSelected: responseClientLayer.colorSelectedStroke,
    strokeWidth: responseClientLayer.strokeWidth,
    zIndex: STYLE_ZINDEX_BACK,
  } as ColorStyle;
  const { countryCode, state, type } = responseClientLayer;

  const title = `${countryCode.toUpperCase()}_${
    // eslint-disable-next-line prefer-template
    state !== '' ? `${state.toUpperCase() + '_'}` : ''
  }${type.toUpperCase()}`;

  return {
    layerColor,
    countryCode,
    state,
    type,
    title,
    loaded: false,
  } as ClientLayer;
};

/**
 * Extracts an array client layers (planable areas with configurations) from a response
 *
 * @param responseClientLayers
 */
export const extractClientLayers = (responseClientLayers: any): ClientLayer[] =>
  responseClientLayers?.map((responseClientLayer: any) =>
    extractClientLayer(responseClientLayer)
  ) ?? ([] as ClientLayer[]);

/**
 * Extract a product from a response
 *
 * @param responseProduct
 */
export const extractProduct = (responseProduct: any): Product => {
  const productPrices =
    responseProduct?.productPrices?.map((productPrice: any) => {
      const { id, price, quantityFrom, quantityTo } = productPrice;
      return { id, price, quantityFrom, quantityTo } as ProductPrice;
    }) ?? [];

  const { id, name, printDocRequired } = responseProduct;
  return {
    id,
    name,
    printDocRequired,
    productPrices,
  } as Product;
};

/**
 * Extract an array of products from a response
 *
 * @param responseProducts
 */
export const extractProducts = (responseProducts: any): Product[] =>
  responseProducts?.map((responseProduct: any) =>
    extractProduct(responseProduct)
  ) ?? ([] as Product[]);

/**
 * Extracts a client from a response
 *
 * @param responseClient
 */
export const extractClient = (responseClient: any): Client => {
  const {
    id,
    uuid,
    areaLayer,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    products,
    name,
    transmissionType,
    weekparts,
    showPrice,
    planningRestriction,
  } = responseClient;

  return {
    id,
    uuid,
    billCity,
    billHousenumber,
    billName,
    billPostcode,
    billStreet,
    name,
    transmissionType,
    clientLocations: [],
    clientLayers: extractClientLayers(areaLayer),
    products: extractProducts(products),
    distributionTemplates: [], // extractDistributionTemplates(distributionTemplates),
    clientMetaData: {} as ClientMetaData,
    history: [],
    weekparts: weekparts?.sort() ?? [config.general.defaultWeekpart],
    showPrice,
    planningRestriction,
    layerTypes: areaLayer
      .map((layer: any) => layer.type)
      .filter(
        (layerType: string, index: number, array: string[]) =>
          array.indexOf(layerType) === index
      )
      .sort(),
  } as Client;
};

/**
 * Exract meta datas of an area (additional information, color styles, ...)
 *
 * @param responseAreaMetaData
 */
export const extractAreaMetaData = (
  responseAreaMetaData: any
): AreaMetaData => {
  const {
    areaKey,
    /* countryCode, type, */ areaStyle,
    info,
  } = responseAreaMetaData;

  return {
    areaKey,
    areaStyle: areaStyle as ColorStyle,
    info: info as ObjectMap,
  } as AreaMetaData;
};

/**
 * Exract an array of meta datas of an area (additional information, color styles, ...)
 *
 * @param responseAreaMetaDatas
 */
export const extractAreaMetaDatas = (
  responseAreaMetaDatas: any
): AreaMetaData[] =>
  (responseAreaMetaDatas?.map((areaMetaData: any) =>
    extractAreaMetaData(areaMetaData)
  ) as AreaMetaData[]) ?? ([] as AreaMetaData[]);

/**
 * Extract all area meta data of a client
 *
 * @param responseMetaData
 */
export const extractClientMetaData = (
  responseMetaData: any
): ClientMetaData => {
  const { id, areaMetaData } = responseMetaData;

  return {
    id,
    areaMetaData: extractAreaMetaDatas(areaMetaData),
  } as ClientMetaData;
};
