/**
 * This file holds network addresses for api endpoints which
 * are used accross the project. The reason is that all
 * constants are held in one place and therefore can be found
 * easier.
 */

export const OPENCAGE_API_KEY = 'b9f8d97a3b334d5fb8de17904ea5c052';
export const ORS_API_KEY =
  '5b3ce3597851110001cf62484bde8fe2467042c1bc4cece1a5219e72';

export const URL_OSM_SERVER = 'https://osm.freyplus.de/hot/{z}/{x}/{y}.png';
export const URL_NOMINATIM = 'https://api.opencagedata.com/geocode/v1/geojson';
export const URL_ORS = 'https://api.openrouteservice.org/v2/';
export const URL_GEOSERVER = (layerTitle: string): string =>
  `https://geoserver.freyplus.de/geoserver/fpm/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=fpm%3A${layerTitle}&outputFormat=application%2Fjson`;

export const SERVICE_AREA_DATA = 'areaDataService/';
export const PATH_AREAS = `${SERVICE_AREA_DATA}areas`;

export const SERVICE_GEO_DATA = 'geoDataService/';
export const PATH_CLIENT_DATA = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}`;

export const PATH_CLIENT_LOCATIONS_GET = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}/client/${clientUUID}/locations`;
export const PATH_CLIENT_LOCATION_NEW = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/location`;
export const PATH_CLIENT_LOCATION_DELETE = (
  clientUUID: string,
  locationId: number
): string => `${SERVICE_GEO_DATA}client/${clientUUID}/location/${locationId}`;
export const PATH_CLIENT_LOCATION_UPDATE = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/location/`;

export const PATH_CLIENT_DISTRIBUTION_TEMPLATES_GET = (
  clientUUID: string
): string => `${SERVICE_GEO_DATA}/client/${clientUUID}/distributionTemplates`;
export const PATH_DISTRIBUTION_TEMPLATE_NEW = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/distributionTemplate`;
export const PATH_DISTRIBUTION_TEMPLATE_DELETE = (
  clientUUID: string,
  templateId: number
): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/distributionTemplate/${templateId}`;
export const PATH_DISTRIBUTION_TEMPLATE_UPDATE = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/distributionTemplate/`;

export const PATH_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_NEW = (
  clientUUID: string,
  locationId: number
): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/${locationId}/distributionTemplate`;
export const PATH_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_DELETE = (
  clientUUID: string,
  locationId: number,
  templateId: number
): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/${locationId}/distributionTemplate/${templateId}`;
export const PATH_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_UPDATE = (
  clientUUID: string,
  locationId: number
): string =>
  `${SERVICE_GEO_DATA}client/${clientUUID}/${locationId}/distributionTemplate`;

export const PATH_CLIENT_HISTORY_GET = (
  clientUUID: string,
  order: boolean
): string =>
  `${SERVICE_GEO_DATA}/client/${clientUUID}/${order ? 'orders' : 'offers'}`;
export const PATH_CLIENT_HISTORY_ITEM_GET = (
  clientUUID: string,
  order: boolean,
  historyId: number
): string =>
  `${SERVICE_GEO_DATA}/client/${clientUUID}/${
    order ? 'order' : 'offer'
  }/${historyId}`;

export const PATH_CLIENT_AREA_META_DATA_GET = (clientUUID: string): string =>
  `${SERVICE_GEO_DATA}/client/${clientUUID}/meta`;

export const SERVICE_SECURITY = 'securityService/';
export const PATH_LOGIN = `${SERVICE_SECURITY}login`;

export const SERVICE_OFFER = 'offerService/';
export const PATH_OFFER_REQUEST = `${SERVICE_OFFER}offerRequest`;

export const SERVICE_DOCUMENT = 'documentService/';
export const PATH_EXPORT_EXCEL = `${SERVICE_DOCUMENT}distributionPlan`;

export const SERVICE_CALCULATION = 'calculationService/';
export const PATH_CALCULATION_FPP = `${SERVICE_CALCULATION}calculationFpp`;

export const SERVICE_ADMINISTRATION = 'administrationService/';
export const PATH_POIS_GET = `${SERVICE_ADMINISTRATION}pois`;

export const OSR_ISOCHRONES = `isochrones/`;

export const PARAM_WEEKPART = 'weekpart';
export const PARAM_CLIENT_ID = 'clientid';
export const PARAM_PARENTORIGIN = 'parentorigin';
export const PARAM_CLIENT_LOCATION_MODE = 'clientlocationmode';
export const PARAM_EMAIL = 'email';
export const PARAM_AREAKEYS = 'areakeys';
export const PARAM_DISTRIBUTION_WEEK = 'distributionweek';
export const PARAM_DISTRIBUTION_YEAR = 'distributionyear';
