import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ClientLocationDistributionTemplateListItemProps } from '../../../@types/Modal.d';

/**
 * Component to show a clientLocation distribution template as a list item
 *
 * @param props
 */
const ClientLocationDistributionTemplateListItem: React.FC<ClientLocationDistributionTemplateListItemProps> = (
  props: ClientLocationDistributionTemplateListItemProps
) => {
  const {
    clientLocationDistributionTemplate,
    selected,
    clientLocation,
    planningRestriction,
    selectClientLocationDistributionTemplate,
    removeClientLocationDistributionTemplate,
  } = props;

  /**
   * Click action to select a clientLocation distribution template
   *
   * @param event
   */
  const onClickItem = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    selectClientLocationDistributionTemplate(
      clientLocationDistributionTemplate
    );
    event.preventDefault();
  };

  /**
   * Click action to remove a clientLocation distribution template
   *
   * @param event
   */
  const onClickRemove = (event: React.MouseEvent): void => {
    event.stopPropagation();

    if (clientLocation)
      removeClientLocationDistributionTemplate(
        clientLocationDistributionTemplate,
        clientLocation
      );
  };

  /**
   * Key down action to remove a clientLocation distribution template
   *
   * @param event
   */
  const onKeyDownRemove = (event: React.KeyboardEvent): void => {
    event.stopPropagation();

    if (clientLocation)
      removeClientLocationDistributionTemplate(
        clientLocationDistributionTemplate,
        clientLocation
      );
  };

  return (
    <ListGroup.Item
      onClick={onClickItem}
      className="clientLocation-distribution-tempalte-item selectable hover-item"
      active={selected}
    >
      <Row className="no-gutters">
        <Col sm={11}>{clientLocationDistributionTemplate.name}</Col>
        <Col sm={1} className="action-buttons ">
          <div
            role="button"
            className={`icon-remove hover-icon ${
              planningRestriction === 'TEMPLATE' ? 'hidden' : ''
            }`}
            tabIndex={0}
            onClick={onClickRemove}
            onKeyUp={onKeyDownRemove}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
          <div
            className={`fav-container ${
              !clientLocationDistributionTemplate.defaultTemplate
                ? 'hidden'
                : ''
            }`}
          >
            <FontAwesomeIcon icon={faStar} />
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ClientLocationDistributionTemplateListItem;
