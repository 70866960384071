import * as React from 'react';

import { Modal, Button, Col } from 'react-bootstrap';

import ClientLocationDistributionTemplateList from './ClientLocationDistributionTemplateList';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_ACCEPT,
  MODAL_TITLE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATES,
} from '../../../constants/labels';

import {
  ClientLocationDistributionTemplateModalProps,
  ClientLocationDistributionTemplateModalState,
} from '../../../@types/Modal.d';
import {
  ClientLocation,
  ClientLocationDistributionTemplate,
} from '../../../@types/Common.d';

/**
 * Modal dialog to show a list of a clientLocations distribution templates
 */
export default class ClientLocationDistributionTemplateModal extends React.Component<
  ClientLocationDistributionTemplateModalProps,
  ClientLocationDistributionTemplateModalState
> {
  constructor(props: ClientLocationDistributionTemplateModalProps) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.onClickApply = this.onClickApply.bind(this);

    this.setClientLocation = this.setClientLocation.bind(this);

    this.selectClientLocationDistributionTemplate = this.selectClientLocationDistributionTemplate.bind(
      this
    );
  }

  /**
   * Method to hide the modal dialog
   */
  onHide(): void {
    this.setState({ clientLocation: undefined }, () => {
      const { showModal } = this.props;

      showModal(false);
    });
  }

  /**
   * Click action to load the selected susidiary distribution template
   *
   * @param event
   */
  onClickApply(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    const { applyClientLocationDistributionTemplate } = this.props;
    const { selectedClientLocationDistributionTemplate } = this.state;

    if (!selectedClientLocationDistributionTemplate) return;

    applyClientLocationDistributionTemplate(
      selectedClientLocationDistributionTemplate
    );
    this.onHide();
    event.preventDefault();
  }

  /**
   * Set the clientLocation which distribution templates are displayed
   *
   * @param clientLocation
   */
  setClientLocation(clientLocation?: ClientLocation): void {
    this.setState({ clientLocation });
  }

  /**
   * Set the selected clientLocation distribution item
   *
   * @param selectedClientLocationDistributionTemplate
   */
  selectClientLocationDistributionTemplate(
    selectedClientLocationDistributionTemplate: ClientLocationDistributionTemplate
  ): void {
    this.setState({ selectedClientLocationDistributionTemplate });
  }

  render(): JSX.Element {
    const {
      show,
      planningRestriction,
      removeClientLocationDistributionTemplate,
      container,
    } = this.props;
    const {
      clientLocation,
      selectedClientLocationDistributionTemplate,
    } = this.state;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {MODAL_TITLE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATES(
              clientLocation?.name
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientLocationDistributionTemplateList
            planningRestriction={planningRestriction}
            clientLocationDistributionTemplates={
              clientLocation?.clientLocationDistributionTemplates ?? []
            }
            selectedClientLocationDistributionTemplate={
              selectedClientLocationDistributionTemplate
            }
            clientLocation={clientLocation}
            selectClientLocationDistributionTemplate={
              this.selectClientLocationDistributionTemplate
            }
            removeClientLocationDistributionTemplate={
              removeClientLocationDistributionTemplate
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci modal-footer-button"
              onClick={this.onClickApply}
            >
              {BUTTON_TITLE_ACCEPT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button className="ci modal-footer-button" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
