import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import ClientLocationAreaListItem from './ClientLocationAreaListItem';

import { sortClientLocations } from '../../util/sortUtil';

import { ClientLocationAreaListProps } from '../../@types/AreaList';

/**
 * Component to show a list of clientLocations with its selected areas
 *
 * @param props
 */
const ClientLocationAreaList: React.FC<ClientLocationAreaListProps> = (
  props: ClientLocationAreaListProps
) => {
  const {
    showAll,
    clientLocations,
    weekpart,
    removeArea,
    removeClientLocation,
    showLocalities,
    hideClientLocationSelection,
  } = props;

  return (
    <ListGroup className="h-100 selected-clientLocation-list">
      {sortClientLocations(clientLocations).map(clientLocation => (
        <ClientLocationAreaListItem
          key={clientLocation.id}
          show={showAll}
          clientLocation={clientLocation}
          removeArea={removeArea}
          removeClientLocation={removeClientLocation}
          showLocalities={showLocalities}
          weekpart={weekpart}
          hideClientLocationSelection={hideClientLocationSelection}
        />
      ))}
    </ListGroup>
  );
};

export default ClientLocationAreaList;
