import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ListAddItemProps } from '../../@types/App.d';

/**
 * Component to trigger the addition of a new item to a list.
 * This is mainly used on the map's slide-in list (clientLocations, templates, ...).
 * A click on this button will launch a modal wtih a form to add new items.
 * The show method for this dialog has to be provided by the parent element.
 *
 * @param props
 */
const ListAddItem: React.FC<ListAddItemProps> = (props: ListAddItemProps) => {
  const { showNewModal, title } = props;

  /**
   * Click action the trigger the adding process
   *
   * @param event
   */
  const onClickItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showNewModal();
  };

  /**
   * Key down action the trigger the adding process
   *
   * @param event
   */
  const onKeyDownItem = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (event.keyCode === 13) showNewModal();
  };

  return (
    <div
      className="add-item"
      role="button"
      tabIndex={-1}
      onKeyDown={onKeyDownItem}
      onClick={onClickItem}
    >
      <Row className="no-gutters">
        <Col md={1}>
          <FontAwesomeIcon icon={faPlus} />
        </Col>
        <Col md={11}>{title}</Col>
      </Row>
    </div>
  );
};

export default ListAddItem;
