import * as React from 'react';

import { Modal, Button, FormControl, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faBicycle, faWalking } from '@fortawesome/free-solid-svg-icons';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_ACCEPT,
  MODAL_TITLE_ISOCHRONE,
  MODAL_ISOCHRONE_RANGE_PLACEHOLDER,
  MODAL_ISOCHRONE_RANGE_LABEL_MINUTES,
  MODAL_ISOCHRONE_RANGE_INVALID_EMPTY,
  MODAL_ISOCHRONE_RANGE_INVALID_VALUE_TOO_HIGH,
  MODAL_ISOCHRONE_RANGE_INVALID_VALUE_INVALID,
  MODAL_ISOCHRONE_CONTENT,
  MODAL_ISOCHRONE_PROFILE_CAR,
  MODAL_ISOCHRONE_PROFILE_BIKE,
  MODAL_ISOCHRONE_PROFILE_WALK,
} from '../../constants/labels';

import { IsochroneModalProps, IsochroneModalState } from '../../@types/Modal.d';
import { OSRProfiles, DynamicPlaningParam } from '../../@types/Common.d';

/**
 * Modal dialog to provide input for an isochrone or dynamic planing.
 */
export default class IsochroneModal extends React.Component<
  IsochroneModalProps,
  IsochroneModalState
> {
  constructor(props: IsochroneModalProps) {
    super(props);

    const { dynamicPlaningParams } = this.props;

    this.state = {
      range: 0,
      profile: OSRProfiles.DRIVING_CAR,
      showAdvanced: false,
      validated: false,
      dynamicPlaningParams: dynamicPlaningParams.map(param => ({ ...param })),
    };

    this.onChangeRange = this.onChangeRange.bind(this);
    this.onChangeProfile = this.onChangeProfile.bind(this);
    this.onChangeDynamicParam = this.onChangeDynamicParam.bind(this);
    this.onClickAccept = this.onClickAccept.bind(this);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  /**
   * Change event for the range value.
   *
   * @param event
   */
  onChangeRange(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      range: +event.currentTarget.value,
    });
  }

  /**
   * Change event for dynamic planing parameter. This is
   * used for all dynamic parameters as they are not known
   * at compile time and are provided by the api.
   *
   * @param event
   */
  onChangeDynamicParam(
    event: React.ChangeEvent<HTMLInputElement>,
    dynamicParam: DynamicPlaningParam
  ): void {
    const { dynamicPlaningParams } = this.state;
    event.stopPropagation();

    const param = dynamicPlaningParams.find(
      dynamicPlaningParam => dynamicPlaningParam.name === dynamicParam.name
    );

    if (param) {
      param.value = event.currentTarget.value;

      this.setState({
        dynamicPlaningParams,
      });
    }
  }

  /**
   * Change the movement profile for the isochrone.
   *
   * @param event
   * @param profile
   */
  onChangeProfile(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    profile: OSRProfiles
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ profile });
  }

  /**
   * Click event to start the isochrone/dynamic planing.
   *
   * @param event
   */
  onClickAccept(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState(
      {
        validated: true,
      },
      () => {
        if (formValid) {
          const { getIsochrone } = this.props;
          const { range, profile, dynamicPlaningParams } = this.state;

          getIsochrone(range, profile, dynamicPlaningParams);
          this.onHide();
        }
      }
    );
  }

  /**
   * Method to hide and reset the modal dialog.
   */
  onHide(): void {
    const { showModal, dynamicPlaningParams } = this.props;

    this.setState({
      range: 0,
      profile: OSRProfiles.DRIVING_CAR,
      showAdvanced: false,
      dynamicPlaningParams,
      validated: false,
    });

    showModal(false);
  }

  /**
   * Show or hide the advanced planing parameters which make
   * the isochrone planing to a dynamic planing.
   *
   * @param event
   */
  toggleAdvancedOptions(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    const { showAdvanced } = this.state;

    event.preventDefault();
    event.stopPropagation();

    this.setState({ showAdvanced: !showAdvanced });
  }

  render(): JSX.Element {
    const { show, container } = this.props;
    const {
      validated,
      range,
      profile,
      // showAdvanced,
      // dynamicPlaningParams,
    } = this.state;

    const rangeValid = +range <= 0 || +range > 60 ? '' : `${range}`;

    let errorMessage;

    if (rangeValid.length === 0) {
      if (range <= 0)
        errorMessage = MODAL_ISOCHRONE_RANGE_INVALID_VALUE_INVALID;
      else if (range > 60)
        errorMessage = MODAL_ISOCHRONE_RANGE_INVALID_VALUE_TOO_HIGH;
      else errorMessage = MODAL_ISOCHRONE_RANGE_INVALID_EMPTY;
    }

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TITLE_ISOCHRONE}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            id="isochrone-modal-form"
            className="isochrone-modal-form"
            onSubmit={this.onClickAccept}
            noValidate
            validated={validated}
          >
            <Form.Row>
              <Col>{MODAL_ISOCHRONE_CONTENT}</Col>
            </Form.Row>
            <Form.Row className="my-1">
              <Col>
                <Form.Group>
                  {/* <Form.Label>{MODAL_ISOCHRONE_RANGE_LABEL}</Form.Label> */}
                  <FormControl
                    as="input"
                    type="number"
                    placeholder={MODAL_ISOCHRONE_RANGE_PLACEHOLDER}
                    onChange={this.onChangeRange}
                    value={`${range}`}
                    max={60}
                    min={1}
                  />
                  <Form.Control
                    value={rangeValid}
                    type="number"
                    hidden
                    onChange={() => {}}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorMessage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="unit-label">
                <Form.Group>{MODAL_ISOCHRONE_RANGE_LABEL_MINUTES}</Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Button
                  className={`ci ${
                    profile === OSRProfiles.DRIVING_CAR ? 'selected' : ''
                  }`}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => this.onChangeProfile(event, OSRProfiles.DRIVING_CAR)}
                >
                  <FontAwesomeIcon icon={faCar} className="mr-1" />
                  {MODAL_ISOCHRONE_PROFILE_CAR}
                </Button>
              </Form.Group>
              <Form.Group as={Col}>
                <Button
                  className={`ci ${
                    profile === OSRProfiles.CYCLING_REGULAR ? 'selected' : ''
                  }`}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => this.onChangeProfile(event, OSRProfiles.CYCLING_REGULAR)}
                >
                  <FontAwesomeIcon icon={faBicycle} className="mr-1" />
                  {MODAL_ISOCHRONE_PROFILE_BIKE}
                </Button>
              </Form.Group>
              <Form.Group as={Col}>
                <Button
                  className={`ci ${
                    profile === OSRProfiles.FOOT_WALKING ? 'selected' : ''
                  }`}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => this.onChangeProfile(event, OSRProfiles.FOOT_WALKING)}
                >
                  <FontAwesomeIcon icon={faWalking} className="mr-1" />
                  {MODAL_ISOCHRONE_PROFILE_WALK}
                </Button>
              </Form.Group>
            </Form.Row>
            {/*  <Form.Row>
              <Button className="ci" onClick={this.toggleAdvancedOptions}>
                <FontAwesomeIcon
                  icon={showAdvanced ? faChevronUp : faChevronDown}
                  className="mr-2"
                />
                {showAdvanced ? BUTTON_TITLE_SIMPLE : BUTTON_TITLE_ADVANCED}
              </Button>
            </Form.Row>
            {showAdvanced &&
              dynamicPlaningParams.map((param: DynamicPlaningParam) => (
                <>
                  <Form.Row className="pt-2">
                    <Col sm={6}>
                      <Form.Label>{param.name}</Form.Label>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Control
                          as="input"
                          type={param.type}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onChangeDynamicParam(event, param)}
                          value={param.value}
                          placeholder={param.name}
                        />
                      </Form.Group>
                    </Col>
                    {param.unit && (
                      <Col sm={6} className="unit-label">
                        <Form.Group>{param.unit}</Form.Group>
                      </Col>
                    )}
                  </Form.Row>
                </>
              ))} */}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Col className="p-0">
            <Button
              type="submit"
              variant="primary"
              className="ci"
              form="isochrone-modal-form"
            >
              {BUTTON_TITLE_ACCEPT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button variant="primary" className="ci" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
