import { Area } from '../@types/Area.d';
import {
  ClientLocation,
  DistributionTemplate,
  HistoryTemplate,
  ClientLocationDistributionTemplate,
} from '../@types/Common.d';

/**
 * Sort an array of area by their areakeys
 *
 * @param areas
 */
export const sortAreas = (areas: Area[]): Area[] =>
  areas.sort((areaA: Area, areaB: Area) => {
    if (areaA.areaKey.toLocaleLowerCase() < areaB.areaKey.toLocaleLowerCase())
      return -1;
    if (areaA.areaKey.toLocaleLowerCase() > areaB.areaKey.toLocaleLowerCase())
      return 1;

    return 0;
  });

/**
 * Sort an array of clientLocations by their names.
 *
 * @param clientLocations
 */
export const sortClientLocations = (
  clientLocations: ClientLocation[]
): ClientLocation[] =>
  clientLocations.sort(
    (clientLocationA: ClientLocation, clientLocationB: ClientLocation) => {
      if (
        clientLocationA.name.toLocaleLowerCase() <
        clientLocationB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        clientLocationA.name.toLocaleLowerCase() >
        clientLocationB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );

/**
 * Sort an array of distribution templates
 * by their names.
 *
 * @param distributionTemplates
 */
export const sortDistributionTemplates = (
  distributionTemplates: DistributionTemplate[]
): DistributionTemplate[] =>
  distributionTemplates.sort(
    (
      distributionTemplateA: DistributionTemplate,
      distributionTemplateB: DistributionTemplate
    ) => {
      if (
        distributionTemplateA.name.toLocaleLowerCase() <
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return -1;
      if (
        distributionTemplateA.name.toLocaleLowerCase() >
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );

/**
 * Sort an array of clientLocation distribution
 * templates bei their names.
 *
 * @param distributionTemplates
 */
export const sortClientLocationDistributionTemplates = (
  distributionTemplates: ClientLocationDistributionTemplate[]
): ClientLocationDistributionTemplate[] =>
  distributionTemplates.sort(
    (
      distributionTemplateA: ClientLocationDistributionTemplate,
      distributionTemplateB: ClientLocationDistributionTemplate
    ) => {
      if (
        distributionTemplateA.name.toLocaleLowerCase() <
          distributionTemplateB.name.toLocaleLowerCase() ||
        distributionTemplateA.defaultTemplate
      )
        return -1;
      if (
        distributionTemplateA.name.toLocaleLowerCase() >
        distributionTemplateB.name.toLocaleLowerCase()
      )
        return 1;

      return 0;
    }
  );

/**
 * Sort an array of history templates by
 * heir ids
 *
 * @param historyTemplates
 */
export const sortHistoryTemplates = (
  historyTemplates: HistoryTemplate[]
): HistoryTemplate[] =>
  historyTemplates.sort(
    (historyTemplateA: HistoryTemplate, historyTemplateB: HistoryTemplate) => {
      if (historyTemplateA.id < historyTemplateB.id) return 1;
      if (historyTemplateA.id > historyTemplateB.id) return -1;
      return 0;
    }
  );
