import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import {
  faTrash,
  faSearch,
  faSquareFull,
  faPen,
  faMap,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RGBColor } from 'react-color';

import ColorPicker from '../Modal/ColorPicker';
import CustomTooltip from '../Common/CustomTooltip';

import {
  rgbTorgba,
  rgbTorgbObject,
  rgbObjectTorgb,
} from '../../util/featureStyle';

import {
  TOOLTIP_CLIENT_LOCATION_SHOW,
  TOOLTIP_CLIENT_LOCATION_EDIT,
  TOOLTIP_CLIENT_LOCATION_REMOVE,
  TOOLTIP_CLIENT_LOCATION_CHANGE_COLOR,
  TOOLTIP_CLIENT_LOCATION_DISTRIBUTION_TEMPLATES_SHOW,
  TOOLTIP_CLIENT_LOCATION_DISTRIBUTION_TEMPLATES_SAVE,
} from '../../constants/labels';

import { ClientLocationListButtonsProps } from '../../@types/ClientLocation.d';

/**
 * Component to provide advanced user interaction for the clientLocation list.
 *
 * @param props
 */
const ClientLocationListButtons: React.FC<ClientLocationListButtonsProps> = (
  props: ClientLocationListButtonsProps
) => {
  const {
    planningRestriction,
    clientLocation,
    showColorPicker,
    showPicker,
    showNewClientLocationDialog,
    changeClientLocationColor,
    deleteClientLocation,
    zoomToClientLocation,
    showClientLocationDistributionTemplates,
    showClientLocationDistributionTemplateModal,
  } = props;

  /**
   * Click event to start editing the clientLocation.
   *
   * @param event
   */
  const onClickEdit = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showNewClientLocationDialog(clientLocation);
  };

  /**
   * Key down event to start editing the clientLocation.
   *
   * @param event
   */
  const onKeyDownEdit = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (event.keyCode === 13) showNewClientLocationDialog(clientLocation);
  };

  /**
   * Click event to set the maps position to the clientLocation.
   *
   * @param event
   */
  const onClickZoom = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    zoomToClientLocation(clientLocation);
  };

  /**
   * Key down event to set the maps position to the clientLocation.
   *
   * @param event
   */
  const onKeyDownZoom = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (event.keyCode === 13) zoomToClientLocation(clientLocation);
  };

  /**
   * Click event to delete the clientLocation.
   *
   * @param event
   */
  const onClickDelete = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    deleteClientLocation(clientLocation);
  };

  /**
   * Key down to delete the clientLocation.
   *
   * @param event
   */
  const onKeyDownDelete = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();

    if (event.keyCode === 13) deleteClientLocation(clientLocation);
  };

  /**
   * Click event to launch the color picker to select a new
   * color for the clientLocation.
   *
   * @param event
   */
  const onClickColor = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showPicker();
  };

  /**
   * Key down event to launch the color picker to select a new
   * color for the clientLocation.
   *
   * @param event
   */
  const onKeyDownColor = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (event.keyCode === 13) showPicker();
  };

  /**
   * Click event to create a new clientLocation distribution template
   * from the current selection of the clientLocation.
   *
   * @param event
   */
  const onClickNewTemplate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showClientLocationDistributionTemplateModal(clientLocation);
  };

  /**
   * Key down event to create a new clientLocation distribution template
   * from the current selection of the clientLocation.
   *
   * @param event
   */
  const onKeyDownNewTemplate = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();

    if (event.keyCode === 13)
      showClientLocationDistributionTemplateModal(clientLocation);
  };

  /**
   * Click event to launch a modal dialog to show the clientLocations distribution
   * templates.
   *
   * @param event
   */
  const onClickClientLocationDistributionTemplate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showClientLocationDistributionTemplates(true, clientLocation);
  };

  /**
   * Key down event to launch a modal dialog to show the clientLocations distribution
   * templates.
   *
   * @param event
   */
  const onKeyDownClientLocationDistributionTemplate = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();

    if (event.keyCode === 13) showClientLocationDistributionTemplates(true);
  };

  /**
   * Callback to set the clientLocations color.
   *
   * @param color
   */
  const acceptColorSelection = (color: RGBColor): void => {
    changeClientLocationColor(rgbObjectTorgb(color, 0.2));
  };

  return (
    <div>
      <Row className="no-gutters">
        <Col>
          <div className="separator" />
        </Col>
      </Row>
      <Row className="no-gutters justify-content-start">
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`clientLocation-${clientLocation.id}-edit`}
            content={TOOLTIP_CLIENT_LOCATION_EDIT}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickEdit}
              onKeyDown={onKeyDownEdit}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faPen} />
            </div>
          </CustomTooltip>
        </Col>
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`clientLocation-${clientLocation.id}-zoom`}
            content={TOOLTIP_CLIENT_LOCATION_SHOW}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickZoom}
              onKeyDown={onKeyDownZoom}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </CustomTooltip>
        </Col>
        {planningRestriction === 'NONE' && (
          <Col xs={1} className="icon-col">
            <CustomTooltip
              tooltipId={`clientLocation-${clientLocation.id}-rem`}
              content={TOOLTIP_CLIENT_LOCATION_REMOVE}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={onClickDelete}
                onKeyDown={onKeyDownDelete}
                className="hover-icon text-center"
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </CustomTooltip>
          </Col>
        )}
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`clientLocation-${clientLocation.id}-dist-show`}
            content={TOOLTIP_CLIENT_LOCATION_DISTRIBUTION_TEMPLATES_SHOW}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickClientLocationDistributionTemplate}
              onKeyDown={onKeyDownClientLocationDistributionTemplate}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faMap} />
            </div>
          </CustomTooltip>
        </Col>
        <Col
          role="button"
          tabIndex={0}
          onClick={onClickColor}
          onKeyDown={onKeyDownColor}
          xs={1}
          className="icon-col"
        >
          <CustomTooltip
            tooltipId={`clientLocation-${clientLocation.id}-color`}
            content={TOOLTIP_CLIENT_LOCATION_CHANGE_COLOR}
          >
            <div className="text-center">
              <FontAwesomeIcon
                icon={faSquareFull}
                color={rgbTorgba(clientLocation.colorSelectedFill)}
              />
              {showColorPicker ? (
                <ColorPicker
                  id={`${clientLocation.id}`}
                  color={rgbTorgbObject(clientLocation.colorSelectedFill)}
                  accept={acceptColorSelection}
                  abort={showPicker}
                />
              ) : null}
            </div>
          </CustomTooltip>
        </Col>
        <Col className="icon-col" />
        <Col xs={1} className="icon-col">
          {planningRestriction !== 'TEMPLATE' && (
            <CustomTooltip
              tooltipId={`clientLocation-${clientLocation.id}-save`}
              content={TOOLTIP_CLIENT_LOCATION_DISTRIBUTION_TEMPLATES_SAVE}
              placement="left-start"
            >
              <div
                role="button"
                tabIndex={0}
                onClick={onClickNewTemplate}
                onKeyDown={onKeyDownNewTemplate}
                className="hover-icon text-center"
              >
                <FontAwesomeIcon icon={faSave} />
              </div>
            </CustomTooltip>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ClientLocationListButtons;
