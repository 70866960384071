import * as React from 'react';

import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../Common/CustomTooltip';

import { AreaListHeaderItemProps } from '../../@types/AreaList.d';

/**
 * Component to enable the user to perfom an action in the area list header component.
 * The specific actions to be performed and icons to be displayed must be provided
 * by the parent component.
 *
 * @param props
 */
const AreaListHeaderItem: React.FC<AreaListHeaderItemProps> = (
  props: AreaListHeaderItemProps
) => {
  const { icon, tooltipLabel, action } = props;

  /**
   * The action that should occur on click
   */
  const onClick = (event: React.MouseEvent): void => {
    event.stopPropagation();

    action();
  };

  /**
   * The action that should occur on key up
   */
  const onKeyUp = (event: React.KeyboardEvent): void => {
    event.stopPropagation();

    if (event.keyCode === 13) action();
  };

  return (
    <Col xs={2} md={1} className="text-right">
      <div
        role="button"
        className="hover-icon"
        tabIndex={0}
        onClick={onClick}
        onKeyUp={onKeyUp}
      >
        <CustomTooltip
          tooltipId={`icon-${tooltipLabel}`}
          content={tooltipLabel}
        >
          <FontAwesomeIcon icon={icon} />
        </CustomTooltip>
      </div>
    </Col>
  );
};

export default AreaListHeaderItem;
