import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import ClientLocationDistributionTemplateListItem from './ClientLocationDistributionTemplateListItem';

import { CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_LIST_EMPTY } from '../../../constants/labels';

import { sortClientLocationDistributionTemplates } from '../../../util/sortUtil';

import { ClientLocationDistributionTemplate } from '../../../@types/Common.d';
import { ClientLocationDistributionTemplateListProps } from '../../../@types/Modal.d';

/**
 * Component to show a list of clientLocation distribution templates
 *
 * @param props
 */
const ClientLocationDistributionTemplateList: React.FC<ClientLocationDistributionTemplateListProps> = (
  props: ClientLocationDistributionTemplateListProps
) => {
  const {
    clientLocationDistributionTemplates,
    selectedClientLocationDistributionTemplate,
    planningRestriction,
    selectClientLocationDistributionTemplate,
    removeClientLocationDistributionTemplate,
    clientLocation,
  } = props;

  return (
    <ListGroup className="clientLocation-distribution-tempalte-list">
      {clientLocationDistributionTemplates.length > 0 ? (
        sortClientLocationDistributionTemplates(
          clientLocationDistributionTemplates
        ).map((template: ClientLocationDistributionTemplate) => (
          <ClientLocationDistributionTemplateListItem
            key={template.id}
            planningRestriction={planningRestriction}
            clientLocationDistributionTemplate={template}
            selected={
              selectedClientLocationDistributionTemplate?.id === template.id
            }
            clientLocation={clientLocation}
            selectClientLocationDistributionTemplate={
              selectClientLocationDistributionTemplate
            }
            removeClientLocationDistributionTemplate={
              removeClientLocationDistributionTemplate
            }
          />
        ))
      ) : (
        <ListGroup.Item>
          {CLIENT_LOCATION_DISTRIBUTION_TEMPLATE_LIST_EMPTY}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default ClientLocationDistributionTemplateList;
