import * as React from 'react';

import { Row } from 'react-bootstrap';
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import ClientLocationAreaListHeaderItem from './AreaListHeaderItem';

import {
  TOOLTIP_CLIENT_LOCATION_COLLAPSE_ALL,
  TOOLTIP_CLIENT_LOCATION_EXPAND_ALL,
  TOOLTIP_CLIENT_LOCATION_REMOVE_ALL,
} from '../../constants/labels';

import { AreaListHeaderProps } from '../../@types/AreaList.d';

/**
 * Component to display a header for a list of selected areas.
 * Actions that should occur for all selections should be made from here.
 * (e.g. collapse all, remove all, ...)
 *
 * @param props
 */
const AreaListHeader: React.FC<AreaListHeaderProps> = (
  props: AreaListHeaderProps
) => {
  const { collapseAll, removeAll, showCollapseAll, showAll } = props;

  /**
   * Action to collapse all selected susidiaries
   */
  const collapseAllItems = (): void => {
    collapseAll();
  };

  /**
   * Action to remove all areas and clientLocations from selection
   */
  const removeAllItems = (): void => {
    removeAll();
  };

  return (
    <Row className="no-gutters area-list-header">
      {showCollapseAll && (
        <ClientLocationAreaListHeaderItem
          icon={showAll ? faAngleDoubleDown : faAngleDoubleUp}
          tooltipLabel={
            showAll
              ? TOOLTIP_CLIENT_LOCATION_COLLAPSE_ALL
              : TOOLTIP_CLIENT_LOCATION_EXPAND_ALL
          }
          action={collapseAllItems}
        />
      )}
      <ClientLocationAreaListHeaderItem
        icon={faTrash}
        tooltipLabel={TOOLTIP_CLIENT_LOCATION_REMOVE_ALL}
        action={removeAllItems}
      />
    </Row>
  );
};

export default AreaListHeader;
