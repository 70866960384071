import * as React from 'react';
import { MapContainerProps, MapContainerState } from '../../@types/Map.d';
import MapComponent from './MapComponent';
import LayerTypeSelection from './LayerTypeSelection';
import { LayerType } from '../../@types/Common.d';
import {
  CONFIRMATION_MODAL_TITLE_INCOMPATIBLE_LAYERS,
  CONFIRMATION_MODAL_CONTENT_INCOMPATIBLE_LAYERS,
} from '../../constants/labels';
import { checkLayerTypeCompatibility } from '../../util/areaUtil';

export default class MapContainer extends React.Component<
  MapContainerProps,
  MapContainerState
> {
  constructor(props: MapContainerProps) {
    super(props);

    const { client } = this.props;

    this.state = { showLayerTypeSelection: client !== undefined };

    this.setLayerType = this.setLayerType.bind(this);

    this.showLayerTypeSelection = this.showLayerTypeSelection.bind(this);
  }

  componentDidMount(): void {
    const { client } = this.props;

    if (client?.layerTypes !== undefined) {
      const { layerTypes } = client;
      const [selectedLayerType] = layerTypes;

      if (layerTypes.length === 0) {
        this.setState({
          selectedLayerType: 'POSTCODE',
          showLayerTypeSelection: false,
        });
      } else if (layerTypes.length === 1) {
        this.setState({
          selectedLayerType,
          showLayerTypeSelection: client && !selectedLayerType,
        });
      } else {
        this.setState({
          showLayerTypeSelection: true,
        });
      }
    }
  }

  componentDidUpdate(prevProps: MapContainerProps): void {
    const { client } = this.props;

    if (client?.id !== prevProps.client?.id) {
      if (client?.layerTypes !== undefined) {
        const { layerTypes } = client;
        const [selectedLayerType] = layerTypes;

        if (layerTypes.length === 0) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            showLayerTypeSelection: false,
          });
        } else if (layerTypes.length === 1)
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            selectedLayerType,
            showLayerTypeSelection: client && !selectedLayerType,
          });
        else {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            showLayerTypeSelection: true,
          });
        }
      }
    }
  }

  /**
   * Set the layer type (POSTCODE, POSTCODE_MAIL, etc. )
   *
   * @param newLayerType
   */
  setLayerType(newLayerType: LayerType): void {
    const { client, showConfimationModal } = this.props;
    const { selectedLayerType } = this.state;

    // Check if old and new layer type are compatible (same structure, eg. postcode based polygones)
    if (
      !checkLayerTypeCompatibility(selectedLayerType, newLayerType) &&
      selectedLayerType
    )
      // If layer types are not compatible show warnug that current selection will be lost
      showConfimationModal(
        true,
        CONFIRMATION_MODAL_TITLE_INCOMPATIBLE_LAYERS,
        CONFIRMATION_MODAL_CONTENT_INCOMPATIBLE_LAYERS,
        () => {
          this.setState({
            selectedLayerType: newLayerType,
            showLayerTypeSelection: client && !newLayerType,
          });
        }
      );
    else
      this.setState({
        selectedLayerType: newLayerType,
        showLayerTypeSelection: client && !newLayerType,
      });
  }

  // Shows the layer type selection overlay
  showLayerTypeSelection(showLayerTypeSelection: boolean): void {
    this.setState({ showLayerTypeSelection });
  }

  render(): JSX.Element {
    const {
      mapRef,
      client,
      lockSelection,
      clientLocationMode,
      weekpart,
      selectedDistributionTemplate,
      applyToAll,
      addRemoveAreaRestrictedPlanning,
      addArea,
      addInitAreas,
      addPerimeterAreas,
      changeFullscreen,
      enableLoadingOverlay,
      getDynamicAreas,
      getMultiSelectionClientLocations,
      isSelectedByCurrentClientLocation,
      removeArea,
      selectClientLocation,
      changeAreaLayerType,
      changeApplyToAll,
    } = this.props;
    const { selectedLayerType, showLayerTypeSelection } = this.state;

    return (
      <div className="map-container">
        <MapComponent
          ref={mapRef}
          client={client}
          lockSelection={lockSelection}
          clientLocationMode={clientLocationMode}
          weekpart={weekpart}
          selectedDistributionTemplate={selectedDistributionTemplate}
          layerType={selectedLayerType}
          changeAreaLayerType={changeAreaLayerType}
          addRemoveAreaRestrictedPlanning={addRemoveAreaRestrictedPlanning}
          addArea={addArea}
          addInitAreas={addInitAreas}
          addPerimeterAreas={addPerimeterAreas}
          changeFullscreen={changeFullscreen}
          enableLoadingOverlay={enableLoadingOverlay}
          getDynamicAreas={getDynamicAreas}
          getMultiSelectionClientLocations={getMultiSelectionClientLocations}
          isSelectedByCurrentClientLocation={isSelectedByCurrentClientLocation}
          removeArea={removeArea}
          selectClientLocation={selectClientLocation}
        />
        {showLayerTypeSelection && (
          <LayerTypeSelection
            applyToAll={applyToAll}
            selectedLayerType={selectedLayerType}
            layerTypes={client?.layerTypes ?? []}
            changeApplyToAll={changeApplyToAll}
            setLayerType={this.setLayerType}
            closeSelection={() => this.showLayerTypeSelection(false)}
          />
        )}
      </div>
    );
  }
}
