import { Feature } from 'ol';
import { ColorLike } from 'ol/colorlike';
import VectorLayer from 'ol/layer/Vector';

// eslint-disable-next-line import/no-cycle
import {
  TRANSMISSION_TYPE_OFFER,
  TRANSMISSION_TYPE_ORDER,
} from '../constants/constants';

// eslint-disable-next-line import/no-cycle
import { Area, AreaSendFormat } from './Area.d';

export interface Client {
  id: number;
  uuid: string;
  name: string;
  billCity: string;
  billHousenumber: string;
  billName: string;
  billPostcode: string;
  billStreet: string;
  showPrice: boolean;
  planningRestriction: PlanningRestriction;

  clientLocations: ClientLocation[];
  clientLayers: ClientLayer[];
  layerTypes?: LayerType[];
  products: Prodcut[];
  distributionTemplates: DistributionTemplate[];
  clientMetaData: ClientMetaData;
  history: OrderHistoryTemplate[] | OfferHistoryTemplate[];
  transmissionType: TransmissionType;
  weekparts: Weekpart[];
}

export interface ClientLocationSend {
  addressName: string;
  city: string;
  colorSelectedFill: string;
  email?: string;
  housenumber: string;
  id: number;
  lat: number;
  lon: number;
  name: string;
  number?: string;
  phone?: string;
  web?: string;
  fax?: string;
  openingHours: OpeningHoursSend[];
  planable: boolean;
  poi: POI;
  postcode: string;
  street: string;
}

export interface ClientLocation extends ClientLocationSend {
  areas: Area[];
  openingHours: OpeningHours;
  price?: LocationPrice;
  selected: boolean;
  show: boolean;
  clientLocationDistributionTemplates: ClientLocationDistributionTemplate[];

  feature?: Feature;
  layer?: VectorLayer;
}

export interface POI {
  id: number;
  active: string;
  inactive: string;
  name: string;
}

export interface Product {
  id: number;
  name: string;
  printDocRequired: boolean;
  productPrices: ProductPrice[];
}

export interface Price {
  id?: number;
  total: string;
  totalInclusiveVat: string;
  totalPerThousand: string;
  vat: string;
}

export interface LocationPrice extends Price {
  circulation: number;
  totalKaufDa: string;
}

export interface TotalPrice extends Price {
  subtotalKaufDa: string;
  subtotalDistribution: string;
  subtotalDistributionPerThousand: string;
  subtotalPrint: string;
  subtotalPrintPerThousand: string;
}

export interface ProductPrice {
  id: number;
  price: Price;
  quantityFrom: number;
  quantityTo: number;
}

export interface PriceResult {
  price: TotalPrice;
  clientLocationPrices?: LocationPrice[];
}

export interface ClientLayer {
  layerColor: ColorStyle;
  countryCode: string;
  state: string;
  type: LayerType;
  title: string;
  loaded: boolean;

  layer?: VectorLayer;
}

export interface ColorStyle {
  fill: ColorLike;
  stroke: ColorLike;
  fillSelected: ColorLike;
  strokeSelected: ColorLike;
  strokeWidth?: number;
  zIndex: number;
}

export interface DistributionTemplate {
  id: number;
  name: string;
  locations: DistributionTemplateLocation[];
}

export interface DistributionTemplateLocation {
  locationId?: number;
  areas: AreaSendFormat[];
}

export interface ClientLocationDistributionTemplate
  extends DistributionTemplateLocation {
  id: number;
  defaultTemplate: boolean;
  name: string;
}

export interface HistoryTemplate {
  id: number;
  actionName?: string;
  distributionDay: string;
  locations: DistributionTemplateLocation[];
}

export interface OfferHistoryTemplate extends HistoryTemplate {}

export interface OrderHistoryTemplate extends HistoryTemplate {}

export enum OSRProfiles {
  DRIVING_CAR = 'driving-car',
  DRIVING_HGV = 'driving-hgv',
  CYCLING_REGULAR = 'cycling-regular',
  FOOT_WALKING = 'foot-walking',
}

export interface AreaDataFormat {
  areaKey: string;
  type: string;
  countryCode: string;
}

export interface DynamicPlaningParam {
  name: string;
  value: string;
  type: string;
  unit?: string;
}

export interface ClientMetaData {
  id: number;
  areaMetaData: AreaMetaData[];
}

export interface AreaMetaData {
  areaKey: string;
  areaStyle: ColorStyle;
  info: { [key: string]: string };
}

export interface ObjectMap {
  [key: string]: string;
}

export interface PrintDimension {
  name: PaperSize;
  dimensions: [number, number];
}

export interface OpeningHours {
  [key: string]: OpeningHour;
}

export interface OpeningHour {
  id?: number;
  morningFrom?: string;
  morningTo?: string;
  noonFrom?: string;
  noonTo?: string;
  continuouslyOpen: boolean;
}

export interface OpeningHoursSend {
  id?: number;
  morningFrom?: string;
  morningTo?: string;
  noonFrom?: string;
  noonTo?: string;
  continuouslyOpen: boolean;
  day: string;
}

export interface LayerTypeDisplay {
  title: string;
  image: string;
}

export type TransmissionType =
  | TRANSMISSION_TYPE_OFFER
  | TRANSMISSION_TYPE_ORDER;

export type Coordinates = { lon: number; lat: number };
export type Weekpart = 'MIDWEEK' | 'WEEKEND' | '';
export type PlanningRestriction = 'NONE' | 'TEMPLATE';
export type PaperSize = 'A0' | 'A1' | 'A2' | 'A3' | 'A4' | 'A5';
export type LayerType = 'POSTCODE' | 'POSTCODE_MAIL' | 'DISTRICT' | 'MAGAZINE';
