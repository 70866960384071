import './styles/App.scss';
import './styles/AreaList.scss';

import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { cloneDeep, remove, forEach } from 'lodash';
import { Feature } from 'ol';

import LocalitiesModal from './components/Modal/LocalitiesModal';
import ClientLocationListContainer from './components/ClientLocationList/ClientLocationListContainer';
import ClientLocationAreaList from './components/AreaList/ClientLocationAreaList';
import MapComponent from './components/Map/MapComponent';
import AreaList from './components/AreaList/AreaList';
import ImportModal from './components/Modal/ImportModal';
import MapMenu from './components/Map/MapButtons';
import LoadingOverlay from './components/Common/CustomLoadingOverlay';
import ClientLocationModal from './components/Modal/ClientLocationModal/ClientLocationModal';
import CirculationItem from './components/Common/CirculationItem';
import DistributionTemplateListContainer from './components/DistributionTemplateList/DistributionTemplateListContainer';
import DistributionTemplateModal from './components/Modal/DistributionTemplateModal';
import ConfirmationModal from './components/Modal/ConfirmationModal';
import ResponseModal from './components/Modal/ResponseModal';
import HistoryContainer from './components/HistoryList/HistoryListContainer';
import ClientLocationDistributionTemplateModal from './components/Modal/ClientLocationDistributionTemplateModal/ClientLocationDistributionTemplateModal';
import PriceItem from './components/Common/PriceItem';
import IsochroneModal from './components/Modal/IsochroneModal';
import AreaListHeader from './components/AreaList/AreaListHeader';
import WarningMessage from './components/Common/WarningMessage';
import PrintMapModal from './components/Modal/PrintModal';
import MapContainer from './components/Map/MapContainer';

import {
  getClientData,
  getAreaData,
  updateClientLocation,
  createClientLocation,
  deleteClientLocation,
  deleteDistributionTemplate,
  createDistributionTemplate,
  createClientLocationDistributionTemplate,
  deleteClientLocationDistributionTemplate,
  getTotalPrice,
  getHistoryData,
  getHistoryItemData,
  getIsochrone,
  getClientAreaMetaData,
  updateDistributionTemplate,
  getClientLocations,
  getClientDistributionTemplates,
} from './util/api';
import {
  LOADING_PLEASE_WAIT,
  LOADING_PROCESS_REQUEST,
  CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE,
  CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE,
  CONFIRMATION_MODAL_TITLE_DELETE_CLIENT_LOCATION,
  CONFIRMATION_MODAL_CONTENT_DELETE_CLIENT_LOCATION,
  RESPONSE_MODAL_FAILURE_TITLE,
  RESPONSE_MODAL_FAILURE_CONTENT,
  CONFIRMATION_MODAL_TITLE_REMOVE_ALL_AREAS,
  CONFIRMATION_MODAL_CONTENT_REMOVE_ALL_AREAS,
  WARNING_MESSAGE_TITLE_NO_CLIENT_LOCATION_SELECTED,
  WARNING_MESSAGE_CONTENT_NO_CLIENT_LOCATION_SELECTED,
  WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
  WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
  CONFIRMATION_MODAL_TITLE_OVERWRITE_DISTRIBUTION_TEMPLATE,
  CONFIRMATION_MODAL_CONTENT_OVERWRITE_DISTRIBUTION_TEMPLATE,
} from './constants/labels';
import {
  WEEKPART_WEEKEND,
  WEEKPART_MIDWEEK,
  FEATURE_FIELD_CLIENT_LOCATION_NAME,
  FEATURE_FIELD_CLIENT_LOCATION_STREET,
  FEATURE_FIELD_CLIENT_LOCATION_HOUSENUMBER,
  FEATURE_FIELD_CLIENT_LOCATION_POSTCODE,
  FEATURE_FIELD_CLIENT_LOCATION_CITY,
  TRANSMISSION_TYPE_ORDER,
  REQUEST_IDENTIFIER_GET_CLIENT_DATA,
  REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS,
  REQUEST_IDENTIFIER_DELETE_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_GET_AREA_DATA,
  REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION,
  REQUEST_IDENTIFIER_CREATE_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION,
  REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_DELETE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_GET_CLIENT_HISTORY,
  REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM,
  REQUEST_IDENTIFIER_GET_ISOCHRONE,
  REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE,
  WEEKPART_ARRAY,
  DUMMY_DYN_PARAMS,
  REQUEST_IDENTIFIER_GET_CLIENT_META,
  LAYER_TYPE_POSTCODE,
  COUNTRY_CODE_DE,
  WarningMessageType,
  REQUEST_IDENTIFIER_PRINT_MAP,
  REQUEST_IDENTIFIER_UPDATE_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_GET_DISTRIBUTION_TEMPLATES,
  REQUEST_IDENTIFIER_DELETE_CLIENT_LOCATION,
} from './constants/constants';
import { sortClientLocations, sortHistoryTemplates } from './util/sortUtil';
import {
  exportAreaCSV,
  exportClientLocationCSV,
  exportExcel,
  getClientLocationsSend,
  getAreasSend,
} from './util/exportUtil';
import {
  extractOrderTemplate,
  extractOfferTemplate,
} from './util/responseUtil/clientResponseUtil';
import {
  addAreaStub,
  addAreaStubs,
  getAreaStubsFromTemplate,
  getAdditionalAreasDifference,
  getAreaDataFormat,
  removeNotSerializableFromAreas,
  removeNotSerializableFromClientLocations,
  removeNotSerializableFromClientLocation,
  calculateAreaCirculation,
  getAllAdditionalAreas,
  getDistributionTemplate,
  getClientLocationDistributionTemplate,
  getAllAreaFeatures,
  calculateAreaCirculationTotal,
  calculateClientLocationCalculation,
  getAreaDescriptions,
  changeAreasLayerType,
} from './util/areaUtil';
import config from './config';
import { generateLocationStyle } from './util/featureStyle';

import { AppProps, AppState } from './@types/App.d';
import {
  Client,
  Coordinates,
  ClientLocation,
  Weekpart,
  DistributionTemplate,
  DistributionTemplateLocation,
  Product,
  ClientLocationDistributionTemplate,
  OrderHistoryTemplate,
  OfferHistoryTemplate,
  OSRProfiles,
  DynamicPlaningParam,
  ClientMetaData,
  PriceResult,
  PaperSize,
  HistoryTemplate,
  TotalPrice,
  LocationPrice,
  ClientLocationSend,
  LayerType,
} from './@types/Common.d';
import {
  MessagePayload,
  MessageType,
  Message,
  AreaMessagePayload,
  HideClientLocationSelectionPayload,
  LocalityMessagePayload,
  MapInfoPayload,
  InitAreasPayload,
} from './@types/MessageTypes.d';
import {
  Area,
  ClientLocationSendFormat,
  AreaDescription,
} from './@types/Area.d';
import { Address } from './@types/Modal.d';
/**
 * The main component that holds the application and controls the
 * data flow.
 */
class App extends React.Component<AppProps, AppState> {
  /**
   * Reference to the mapcomponent
   */
  private mapComponentRef = React.createRef<MapComponent>();

  /**
   * Reference to the mapcotainer
   */
  private mapContainerRef = React.createRef<MapContainer>();

  /**
   * Reference to the fillscreen container
   */
  private fullScreenContainerRef = React.createRef<any>();

  /**
   * Reference to the localities modal
   */
  private localitiesModalRef = React.createRef<LocalitiesModal>();

  /**
   * Reference to the client location modal
   */
  private clientLocationModalRef = React.createRef<ClientLocationModal>();

  /**
   * Reference to the distribution template modal
   */
  private templateModalRef = React.createRef<DistributionTemplateModal>();

  /**
   * Reference to the clientLocation distribution template modal
   */
  private clientLocationDistributionTemplateModalRef = React.createRef<
    ClientLocationDistributionTemplateModal
  >();

  /**
   * Reference to the clientLocation distribution template modal
   */
  private warningMessageRef = React.createRef<WarningMessage>();

  constructor(props: AppProps) {
    super(props);

    const {
      initWeekpart,
      initDistributionWeek,
      initDistributionYear,
    } = this.props;

    this.state = {
      weekpart: initWeekpart,
      distributionWeek: initDistributionWeek,
      distributionYear: initDistributionYear,
      totalCirculation: 0,

      isFullscreen: false,
      isLoading: false,
      pendingRequests: [],
      lockSelection: false,
      applyLayerTypeToAll: false,

      showImportModal: false,
      showLocalitiesModal: false,
      showClientLocationList: false,
      showDistributionTemplateList: false,
      showHistoryList: false,
      showClientLocationModal: false,
      showDistributionTemplateModal: false,
      showConfirmationModal: false,
      showResponseModal: false,
      showClientLocationDistributionTemplateModal: false,
      showIsochroneModal: false,
      showPrintMapModal: false,
      showAllAreaItems: true,
      showWarningMessage: false,
      showRevertPerimeterButton: false,

      areas: [] as Area[],
      selectedClientLocations: [] as ClientLocation[],
    };

    this.getAllAreasFromClientLocations = this.getAllAreasFromClientLocations.bind(
      this
    );
    this.addArea = this.addArea.bind(this);
    this.addAreas = this.addAreas.bind(this);
    this.addRemoveAreaRestrictedPlanning = this.addRemoveAreaRestrictedPlanning.bind(
      this
    );
    this.addPerimeterAreas = this.addPerimeterAreas.bind(this);
    this.getDynamicAreas = this.getDynamicAreas.bind(this);
    this.addInitialAreas = this.addInitialAreas.bind(this);
    this.addHistory = this.addHistory.bind(this);
    this.removeArea = this.removeArea.bind(this);
    this.removeAreas = this.removeAreas.bind(this);
    this.removeAllAreas = this.removeAllAreas.bind(this);
    this.removeAllAreasCallback = this.removeAllAreasCallback.bind(this);
    this.revertPerimeter = this.revertPerimeter.bind(this);
    this.removeClientLocationFromSelection = this.removeClientLocationFromSelection.bind(
      this
    );
    this.processNewAreas = this.processNewAreas.bind(this);
    this.updateAreaSelection = this.updateAreaSelection.bind(this);
    this.templateModalCallback = this.templateModalCallback.bind(this);
    this.drawPerimeter = this.drawPerimeter.bind(this);
    this.convertAreaLayerType = this.convertAreaLayerType.bind(this);

    this.getClientAreaMetaData = this.getClientAreaMetaData.bind(this);
    this.getClientHistory = this.getClientHistory.bind(this);
    this.getClientLocations = this.getClientLocations.bind(this);
    this.getClientDistributionTemplates = this.getClientDistributionTemplates.bind(
      this
    );

    this.isSelectedByCurrentClientLocation = this.isSelectedByCurrentClientLocation.bind(
      this
    );
    this.getMultiSelectionClientLocations = this.getMultiSelectionClientLocations.bind(
      this
    );
    this.getConflictingAreas = this.getConflictingAreas.bind(this);

    this.onMessageReceive = this.onMessageReceive.bind(this);
    this.initMessage = this.initMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.updateClientLocation = this.updateClientLocation.bind(this);
    this.createClientLocation = this.createClientLocation.bind(this);
    this.deleteClientLocation = this.deleteClientLocation.bind(this);

    this.removeClientLocationDistributionTemplate = this.removeClientLocationDistributionTemplate.bind(
      this
    );
    this.applyClientLocationDistributionTemplate = this.applyClientLocationDistributionTemplate.bind(
      this
    );
    this.applyInitAreas = this.applyInitAreas.bind(this);
    this.createClientLocationDistributionTemplate = this.createClientLocationDistributionTemplate.bind(
      this
    );

    this.changeFullscreen = this.changeFullscreen.bind(this);
    this.enableLoadingOverlay = this.enableLoadingOverlay.bind(this);
    this.changeApplyLayerTypeToAll = this.changeApplyLayerTypeToAll.bind(this);

    this.showImportModal = this.showImportModal.bind(this);
    this.showLocalitiesModal = this.showLocalitiesModal.bind(this);
    this.showClientLocationModal = this.showClientLocationModal.bind(this);
    this.showDistributionTemplateModal = this.showDistributionTemplateModal.bind(
      this
    );
    this.showHistoryList = this.showHistoryList.bind(this);
    this.showResponseModal = this.showResponseModal.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.showClientLocationDistributionTemplates = this.showClientLocationDistributionTemplates.bind(
      this
    );
    this.showIsochroneModal = this.showIsochroneModal.bind(this);
    this.showAllAreaItems = this.showAllAreaItems.bind(this);
    this.showWarningMessage = this.showWarningMessage.bind(this);
    this.showRevertPerimeter = this.showRevertPerimeter.bind(this);
    this.showPrintMapModal = this.showPrintMapModal.bind(this);
    this.showLayerTypeSelection = this.showLayerTypeSelection.bind(this);

    this.centerLocation = this.centerLocation.bind(this);
    this.fitSelection = this.fitSelection.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.showClientLocationList = this.showClientLocationList.bind(this);
    this.showDistributionTemplateList = this.showDistributionTemplateList.bind(
      this
    );
    this.toggleClientLocationList = this.toggleClientLocationList.bind(this);
    this.toggleDistributionTemplateList = this.toggleDistributionTemplateList.bind(
      this
    );
    this.toggleHistoryList = this.toggleHistoryList.bind(this);
    this.toggleWeekpart = this.toggleWeekpart.bind(this);
    this.setWeekpart = this.setWeekpart.bind(this);
    this.zoomToClientLocation = this.zoomToClientLocation.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.zoomToAddress = this.zoomToAddress.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.applyHistoryTemplate = this.applyHistoryTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);
    this.getIsochrone = this.getIsochrone.bind(this);

    this.setClientLocationSelected = this.setClientLocationSelected.bind(this);
    this.addClientLocationToSelection = this.addClientLocationToSelection.bind(
      this
    );
    this.removeClientLocationFromSelection = this.removeClientLocationFromSelection.bind(
      this
    );

    this.applyDistributionTemplate = this.applyDistributionTemplate.bind(this);
    this.removeDistributionTemplate = this.removeDistributionTemplate.bind(
      this
    );
    this.createDistributionTemplate = this.createDistributionTemplate.bind(
      this
    );
    this.updateDistributionTemplate = this.updateDistributionTemplate.bind(
      this
    );
    this.updateDistributionTemplateCallback = this.updateDistributionTemplateCallback.bind(
      this
    );

    this.setLocalitiesSelection = this.setLocalitiesSelection.bind(this);
    this.findArea = this.findArea.bind(this);
    this.hideClientLocationSelection = this.hideClientLocationSelection.bind(
      this
    );
  }

  /**
   * Used to initalize the application
   */
  async componentDidMount(): Promise<void> {
    const { clientUUID } = this.props;

    // Add an eventlistener to communicate with an overlaying portal (e.g. FPP)
    window.addEventListener('message', this.onMessageReceive);

    // If a client id is provided continue in clientLocation mode
    if (clientUUID) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
      // Get the corresponding client
      const client = (await getClientData(clientUUID)) as Client;

      // If a valid client is revceived continue in subsidary mode
      if (client) {
        this.setState({
          client,
          lockSelection: client.planningRestriction === 'TEMPLATE',
        });

        // Get additional client data
        this.getClientHistory();
        this.getClientAreaMetaData();
        this.getClientLocations();
        this.getClientDistributionTemplates();
      } else {
        this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
      }
    }
  }

  /**
   * Receive messages from the overlaying portal (e.g. FPP)
   */
  onMessageReceive(event: MessageEvent): void {
    let { data } = event;

    if ((data as Message).type) {
      data = data as Message;
      const { type } = data;

      if (type === MessageType.MESSAGE_TYPE_INIT_AREAS) {
        if (data.payload) this.applyInitAreas(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_ADD_CLIENT_LOCATION) {
        // TODO
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_CLIENT_LOCATION) {
        if (data.payload) this.removeClientLocationFromSelection(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_AREA) {
        if (data.payload) this.removeArea(this.findArea(data.payload));
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_AREAS) {
        // TODO
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES) {
        if ((data.payload as Area).areaKey)
          this.showLocalitiesModal(this.findArea(data.payload), true);
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES_EXTERNAL) {
        if ((data.payload as Area).areaKey)
          this.setLocalitiesSelection(data.payload as Area);
      } else if (type === MessageType.MESSAGE_TYPE_CHANGE_WEEKPART) {
        if (
          data.payload === WEEKPART_MIDWEEK ||
          data.payload === WEEKPART_WEEKEND
        ) {
          this.setWeekpart(data.payload);
        }
      } else if (type === MessageType.MESSAGE_TYPE_ADD_ORDER) {
        if (data.payload) this.addHistory(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_CHANGE_PRODUCT) {
        if (data.payload) this.setSelectedProduct(data.payload as Product);
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_ALL_AREAS)
        this.removeAllAreas();
      else if (
        type === MessageType.MESSAGE_TYPE_HIDE_CLIENT_LOCATION_SELECTION
      ) {
        this.hideClientLocationSelection(
          (data.payload as HideClientLocationSelectionPayload).clientLocationId,
          (data.payload as HideClientLocationSelectionPayload).show
        );
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_PRICE) {
        this.setState({
          price: data.payload as TotalPrice,
        });
      } else if (type === MessageType.MESSAGE_TYPE_MAP_INFO) {
        this.sendMessage(this.getMapInfo(), MessageType.MESSAGE_TYPE_MAP_INFO);
      }
    }
  }

  /**
   * Generate an info object that show the current state
   * of the map
   */
  getMapInfo(): MapInfoPayload {
    const { clientLocationMode } = this.props;
    const {
      areas,
      selectedClientLocations,
      totalCirculation,
      price,
      weekpart,
    } = this.state;

    return {
      areas: clientLocationMode ? selectedClientLocations : areas,
      totalCirculation,
      price,
      weekpart,
    } as MapInfoPayload;
  }

  /**
   * Get an array of all areas selected by all clientLocations
   */
  getAllAreasFromClientLocations(): Area[] {
    const { selectedClientLocations } = this.state;
    return selectedClientLocations.reduce(
      (acc, selectedClientLocation) => [
        ...acc,
        ...selectedClientLocation.areas,
      ],
      [] as Area[]
    ) as Area[];
  }

  /**
   * Get the total cirulation number of all selected areas
   */
  getTotalCirculation(): number {
    const { clientLocationMode } = this.props;
    const { areas, selectedClientLocations } = this.state;

    if (clientLocationMode)
      return selectedClientLocations.reduce(
        (acc: number, clientLocation: ClientLocation) => {
          let rAcc = acc;
          rAcc += calculateClientLocationCalculation(clientLocation);

          return rAcc;
        },
        0
      );

    return areas.reduce((acc: number, area: Area) => {
      let rAcc = acc;
      rAcc += calculateAreaCirculationTotal(area);
      return rAcc;
    }, 0);
  }

  /**
   * Get the total price of all selected areas
   */
  async getPrice(sendMessage?: boolean): Promise<TotalPrice | undefined> {
    const { clientLocationMode } = this.props;
    const {
      selectedClientLocations,
      areas,
      selectedProduct,
      weekpart,
      client,
    } = this.state;

    if (!client?.showPrice && !config.general.showPrice) return undefined;

    const locations = clientLocationMode
      ? getClientLocationsSend(selectedClientLocations)
      : ([
          { id: -1, areas: getAreasSend(areas) },
        ] as ClientLocationSendFormat[]);

    const { price, clientLocationPrices } = (await getTotalPrice(
      locations,
      weekpart,
      selectedProduct
    )) as PriceResult;

    selectedClientLocations.forEach(
      // eslint-disable-next-line no-return-assign
      clientLocation =>
        (clientLocation.price = clientLocationPrices?.find(
          (clientLocationPrice: LocationPrice) =>
            clientLocationPrice.id === clientLocation.id
        ))
    );

    this.setState({ price });

    if (sendMessage)
      this.sendMessage(price, MessageType.MESSAGE_TYPE_UPDATE_PRICE);

    return price;
  }

  /**
   * Check if an areakey is selected by multiple clientLocations
   * and return them
   *
   * @param areaKey
   */
  getMultiSelectionClientLocations(areaKey: string): ClientLocation[] {
    const { selectedClientLocations } = this.state;

    const clientLocations = selectedClientLocations.filter(clientLocation =>
      clientLocation.areas.some((area: Area) => area.areaKey === areaKey)
    );

    return clientLocations;
  }

  /**
   * Returns all area objects of an area that is selected
   * by mutliple clientLocations
   *
   * @param area
   */
  getConflictingAreas(area: Area): Area[] {
    const conflictingClientLocations = this.getMultiSelectionClientLocations(
      area.areaKey
    );
    remove(conflictingClientLocations, { id: area.clientLocationId });

    if (conflictingClientLocations.length > 0) {
      const conflictingAreas = conflictingClientLocations.reduce(
        (acc, clientLocation) => {
          const sArea = clientLocation.areas.find(
            fArea => fArea.areaKey === area.areaKey
          );
          if (sArea) return [...acc, ...[sArea]];
          return acc;
        },
        [] as Area[]
      );

      return conflictingAreas;
    }

    return [] as Area[];
  }

  /**
   * Sends an api call that returns all history items of
   * the currently selected client
   */
  async getClientHistory(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY);

    const order = client?.transmissionType === TRANSMISSION_TYPE_ORDER;

    const res = await getHistoryData(client, order);
    let history;

    if (!Number.isNaN(+res)) history = [] as OrderHistoryTemplate[];
    else if (order) history = res as OrderHistoryTemplate[];
    else history = res as OfferHistoryTemplate[];

    client.history = history;

    this.setState({ client }, () =>
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY)
    );
  }

  /**
   * Sends an api call that returns all client meta data
   */
  async getClientAreaMetaData(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_META);

    const res = await getClientAreaMetaData(client);
    let clientMetaData;

    if (!Number.isNaN(+res)) clientMetaData = {} as ClientMetaData;
    else clientMetaData = res as ClientMetaData;

    client.clientMetaData = clientMetaData;

    this.setState({ client }, () => {
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_META);

      const { current } = this.mapComponentRef;

      if (current) current.insertMetaData();
    });
  }

  /**
   * Sends an api call that returns all client locations
   */
  async getClientLocations(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);

    const res = await getClientLocations(client);
    let clientLocations;

    if (!Number.isNaN(+res)) clientLocations = [] as ClientLocation[];
    else clientLocations = res as ClientLocation[];

    client.clientLocations = clientLocations;

    this.setState({ client }, () => {
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);

      // If the client has at least one clientLocation set the first one selected
      if (
        client.clientLocations.length > 0 &&
        client.planningRestriction === 'NONE'
      ) {
        const firstClientLocation = sortClientLocations(
          client.clientLocations
        ).filter(clientLocation => clientLocation.planable)[0];
        this.setClientLocationSelected(firstClientLocation);

        const { current } = this.mapComponentRef;

        if (current === null) return;

        client.clientLocations.map(location =>
          current.appendLocationToMap(location)
        );
      }
    });
  }

  /**
   * Sends an api call that returns all client sistribution templates
   */
  async getClientDistributionTemplates(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_GET_DISTRIBUTION_TEMPLATES
    );

    const res = await getClientDistributionTemplates(client);
    let clientDistributionTemplates;

    if (!Number.isNaN(+res))
      clientDistributionTemplates = [] as DistributionTemplate[];
    else clientDistributionTemplates = res as DistributionTemplate[];

    client.distributionTemplates = clientDistributionTemplates;

    this.setState({ client }, () => {
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_GET_DISTRIBUTION_TEMPLATES
      );
    });
  }

  /**
   * Sends an api call that returns a feature
   * which represents the range one can reach
   * by a specific movement profile in a given
   * time.
   *
   * @param range
   * @param profile
   * @param dynamicPlaningParams
   */
  async getIsochrone(
    range: number,
    profile: OSRProfiles,
    dynamicPlaningParams: DynamicPlaningParam[]
  ): Promise<void> {
    const { client, selectedClientLocations } = this.state;

    const selectedClientLocation = selectedClientLocations.find(
      clientLocation => clientLocation.selected
    );

    if (!selectedClientLocation || !client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_ISOCHRONE);

    const res = await getIsochrone(
      profile,
      range * 60,
      client,
      selectedClientLocation
    );

    if (res) {
      const { current } = this.mapComponentRef;

      if (current !== null) current.markIsochrone(res, dynamicPlaningParams);
    } else this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_ISOCHRONE);
  }

  /**
   * Sends an api call with a dynamic parameters array
   *  through whic hthe selecteion will be optimized.
   *
   * @param areaDescription
   * @param dynamicPlaningParams
   */
  getDynamicAreas(
    areaDescription: AreaDescription[],
    dynamicPlaningParams: DynamicPlaningParam[]
  ): void {
    const { selectedClientLocations } = this.state;

    let selectedAreas: Area[] = [];
    const currentClientLocation = selectedClientLocations.find(
      clientLocation => clientLocation.selected
    );
    if (currentClientLocation) {
      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(
          currentClientLocation.areas,
          false,
          currentClientLocation.show
        );
      currentClientLocation.areas = [] as Area[];

      addAreaStubs(
        currentClientLocation.areas,
        areaDescription,
        currentClientLocation
      );

      selectedAreas = this.getAllAreasFromClientLocations();
    }

    if (selectedAreas) {
      this.setState({ selectedClientLocations }, () =>
        this.updateAreaSelection(
          selectedAreas,
          true,
          true,
          [],
          dynamicPlaningParams
        )
      );
    }
  }

  /**
   * Sets the selection of localities for an area object.
   * This is also used to split localities between clientLocations
   * that select the same area.
   *
   * @param area
   */
  setLocalitiesSelection(area: Area): void {
    const fArea = this.findArea(area);

    if (!fArea) return;

    fArea.localities.forEach(locality => {
      const fLocality = area.localities.find(
        pLocality => pLocality.localityKey === locality.localityKey
      );
      if (fLocality) locality.selected = fLocality.selected;
    });

    if (fArea.circulation >= 0)
      fArea.circulation = calculateAreaCirculation(fArea);

    const { weekpart, price, client } = this.state;

    fArea.circulationTotal = calculateAreaCirculationTotal(fArea);

    const conflictingClientLocations = this.getMultiSelectionClientLocations(
      area.areaKey
    );
    remove(conflictingClientLocations, { id: fArea.clientLocationId });
    const conflictingAreas = this.getConflictingAreas(fArea);
    const totalCirculation = this.getTotalCirculation();

    if (conflictingAreas.length > 0) {
      conflictingAreas.forEach(conflictingArea => {
        conflictingArea.localities.forEach(locality => {
          const sLocality = area.localities.find(
            pLocality => pLocality.localityKey === locality.localityKey
          );
          if (sLocality && sLocality.selected && locality.selected)
            locality.selected = false;
        });

        conflictingArea.circulation = calculateAreaCirculation(conflictingArea);
        conflictingArea.circulationTotal = calculateAreaCirculationTotal(fArea);

        this.sendMessage(
          {
            area: conflictingArea,
            totalCirculation,
            weekpart,
            price,
          } as LocalityMessagePayload,
          MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES
        );
      });
    }

    this.setState(
      {
        lockSelection: false || client?.planningRestriction === 'TEMPLATE',
        totalCirculation,
      },
      () => {
        const { areas } = this.state;

        this.sendMessage(
          {
            ...(config.general.isShop ? { areas } : { area: fArea }),
            totalCirculation,
            weekpart,
          } as LocalityMessagePayload,
          MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES
        );
        this.getPrice(true);
      }
    );
  }

  /**
   * Sets a given clientLocation the currently selected one
   *
   * @param pClientLocation
   */
  setClientLocationSelected(pClientLocation?: ClientLocation | Feature): void {
    const { client, lockSelection } = this.state;

    if (!client || (lockSelection && client.planningRestriction === 'TEMPLATE'))
      return;

    let clientLocation: ClientLocation | undefined;
    const { clientLocations } = client;

    if (pClientLocation instanceof Feature) {
      // TODo find clientlocation with feature
      clientLocation = clientLocations.find(
        (fClientLocation: ClientLocation) => {
          const { name, street, housenumber, postcode, city } = fClientLocation;
          return (
            (pClientLocation as Feature).get(
              FEATURE_FIELD_CLIENT_LOCATION_NAME
            ) === name &&
            (pClientLocation as Feature).get(
              FEATURE_FIELD_CLIENT_LOCATION_STREET
            ) === street &&
            (pClientLocation as Feature).get(
              FEATURE_FIELD_CLIENT_LOCATION_HOUSENUMBER
            ) === housenumber &&
            (pClientLocation as Feature).get(
              FEATURE_FIELD_CLIENT_LOCATION_POSTCODE
            ) === postcode &&
            (pClientLocation as Feature).get(
              FEATURE_FIELD_CLIENT_LOCATION_CITY
            ) === city
          );
        }
      );
    } else {
      clientLocation = pClientLocation;
    }

    const selectedClientLocations = clientLocations.filter(
      (selectedClientLocation: ClientLocation) =>
        selectedClientLocation.selected
    );

    selectedClientLocations.forEach(
      (selectedClientLocation: ClientLocation) => {
        // eslint-disable-next-line no-unused-expressions
        selectedClientLocation.feature?.setStyle(
          generateLocationStyle(
            selectedClientLocation.poi.inactive ??
              require('./resources/img/POI.png')
          )
        );
        selectedClientLocation.selected = false;
      }
    );

    this.showWarningMessage(false);

    if (clientLocation) {
      clientLocation = clientLocation as ClientLocation;

      // eslint-disable-next-line no-unused-expressions
      clientLocation.feature?.setStyle(
        generateLocationStyle(
          clientLocation.poi.active ?? require('./resources/img/POI.png')
        )
      );
      clientLocation.selected = true;
    }

    this.setState(
      {
        lockSelection:
          !clientLocation?.selected ||
          client.planningRestriction === 'TEMPLATE',
      },
      () => {
        if (clientLocation) {
          this.zoomToClientLocation(clientLocation);
          this.addClientLocationToSelection(clientLocation);
        }
      }
    );
  }

  /**
   * Changes the currently selected weekpart
   *
   * @param weekpart
   */
  setWeekpart(weekpart: Weekpart): void {
    const { clientLocationMode } = this.props;
    this.setState(
      {
        weekpart,
      },
      () => {
        const { areas } = this.state;
        let selectedAreas = areas;

        if (clientLocationMode)
          selectedAreas = this.getAllAreasFromClientLocations();
        this.updateAreaSelection(selectedAreas);
      }
    );
  }

  /**
   * Changes the currently selected product.
   *
   * @param selectedProduct
   */
  setSelectedProduct(selectedProduct: Product): void {
    this.setState({ selectedProduct }, () => {
      this.getPrice(true);
    });
  }

  /**
   * Search for the reference of an area in all
   * selected areas and clientLocations.
   *
   * @param area
   */
  findArea(area: Area): Area | undefined {
    const { selectedClientLocations, areas } = this.state;
    const { clientLocationMode } = this.props;
    let fArea: Area | undefined;
    if (clientLocationMode) {
      const clientLocation = selectedClientLocations.find(
        fClientLocation => fClientLocation.id === area.clientLocationId
      );
      if (!clientLocation) return fArea;

      fArea = clientLocation.areas.find(
        (clientLocationArea: Area) =>
          clientLocationArea.areaKey === area.areaKey
      );

      if (!fArea) {
        forEach(clientLocation.areas, pArea => {
          fArea = pArea.additionalAreas.find(
            (aArea: Area) => aArea.areaKey === area.areaKey
          );
          if (fArea) return false;
          return true;
        });
      }
    } else {
      fArea = areas.find(pArea => pArea.areaKey === area.areaKey);

      if (!fArea) {
        forEach(areas, pArea => {
          fArea = pArea.additionalAreas.find(
            (aArea: Area) => aArea.areaKey === area.areaKey
          );
          if (fArea) return false;
          return true;
        });
      }
    }

    return fArea;
  }

  /**
   * Add a clientLocation to the array of selected clientLocations
   *
   * @param clientLocation
   */
  addClientLocationToSelection(clientLocation: ClientLocation): void {
    const { selectedClientLocations } = this.state;

    if (
      !selectedClientLocations.find(
        fClientLocation => fClientLocation.id === clientLocation.id
      ) &&
      clientLocation.planable
    ) {
      if (!clientLocation.areas) clientLocation.areas = [] as Area[];

      selectedClientLocations.push(clientLocation);
      this.setState(
        { selectedClientLocations: [...selectedClientLocations] },
        () =>
          this.sendMessage(
            clientLocation,
            MessageType.MESSAGE_TYPE_ADD_CLIENT_LOCATION
          )
      );
    }
  }

  /**
   * Removes a clientLocation from the array of selected clientLocations
   *
   * @param clientLocation
   */
  removeClientLocationFromSelection(clientLocation: ClientLocation): void {
    const { selectedClientLocations, client } = this.state;

    const fClientLocation = selectedClientLocations.find(
      pClientLocation => pClientLocation.id === clientLocation.id
    );

    if (!fClientLocation) return;

    const removeAreas = fClientLocation.areas;
    fClientLocation.areas = [] as Area[];
    fClientLocation.selected = false;
    fClientLocation.price = undefined;
    remove(
      selectedClientLocations,
      selectedClientLocation => selectedClientLocation.id === fClientLocation.id
    );
    this.setState(
      {
        lockSelection:
          selectedClientLocations.every(
            selectedClientLocation => !selectedClientLocation.selected
          ) || client?.planningRestriction === 'TEMPLATE',
        selectedClientLocations: [...selectedClientLocations],
      },
      () => {
        const areas = this.getAllAreasFromClientLocations();

        if ((areas && areas.length > 0) || removeAreas.length > 0) {
          this.updateAreaSelection(areas, false, false, removeAreas);
        } else {
          this.sendMessage(
            fClientLocation,
            MessageType.MESSAGE_TYPE_REMOVE_CLIENT_LOCATION
          );
          this.setState({ totalCirculation: this.getTotalCirculation() });
        }
      }
    );
  }

  /**
   * Essentially checks if a planing restricted client can
   * add or remove a provieded area.
   *
   * @param areaDescription
   */
  addRemoveAreaRestrictedPlanning(
    area: AreaDescription | Area,
    removeArea: boolean
  ): void {
    const { selectedDistributionTemplate } = this.state;

    if (
      !selectedDistributionTemplate ||
      (selectedDistributionTemplate?.length ?? 0) === 0
    )
      return;

    const location = selectedDistributionTemplate.find(fLocation =>
      fLocation.areas.find(fArea => fArea.areaKey === area.areaKey)
    );

    if (!location) return;

    if (removeArea) {
      this.removeArea({
        ...area,
        ...{ clientLocationId: location.locationId },
      } as Area);
      return;
    }

    this.addArea(
      {
        ...area,
        ...{ clientLocationId: location.locationId },
      } as AreaDescription,
      false
    );
  }

  /**
   * Adds an area to the selection
   *
   * @param areaDescription
   * @param ignoreConflictingAreas
   */
  addArea(
    areaDescription: AreaDescription,
    ignoreConflictingAreas: boolean = false
  ): void {
    const { areas, selectedClientLocations, client } = this.state;
    const { clientLocationMode } = this.props;

    let selectedAreas;
    if (clientLocationMode) {
      let currentClientLocation;

      if (areaDescription.clientLocationId)
        currentClientLocation = selectedClientLocations.find(
          fClientLocation =>
            fClientLocation.id === areaDescription.clientLocationId
        );
      else
        currentClientLocation = selectedClientLocations.find(
          fClientLocation => fClientLocation.selected
        );

      if (currentClientLocation && currentClientLocation.areas)
        addAreaStub(
          currentClientLocation.areas,
          areaDescription,
          currentClientLocation
        );
      else if (client?.planningRestriction === 'NONE')
        this.showWarningMessage(
          true,
          WARNING_MESSAGE_TITLE_NO_CLIENT_LOCATION_SELECTED,
          WARNING_MESSAGE_CONTENT_NO_CLIENT_LOCATION_SELECTED,
          WarningMessageType.WARN
        );

      selectedAreas = this.getAllAreasFromClientLocations();
    } else {
      addAreaStub(areas, areaDescription);
      selectedAreas = areas;
    }

    this.updateAreaSelection(
      selectedAreas,
      ignoreConflictingAreas,
      false,
      undefined
    );
  }

  /**
   * Add multiple areas to the selection
   *
   * @param areaDescriptions
   * @param appendToSelection
   * @param fitSelection
   * @param ignoreConflictingAreas
   */
  addAreas(
    areaDescriptions: AreaDescription[],
    appendToSelection: boolean = false,
    fitSelection?: boolean,
    ignoreConflictingAreas: boolean = false
  ): void {
    let { areas } = this.state;
    const { selectedClientLocations, client } = this.state;
    const { clientLocationMode } = this.props;

    let selectedAreas: Area[] = [];
    if (clientLocationMode) {
      const currentClientLocation = selectedClientLocations.find(
        fClientLocation => fClientLocation.selected
      );
      if (currentClientLocation) {
        if (!appendToSelection) {
          if (this.mapComponentRef.current !== null)
            this.mapComponentRef.current.markSelectedAreas(
              currentClientLocation.areas,
              false,
              currentClientLocation.show
            );
          currentClientLocation.areas = [] as Area[];
        }
        addAreaStubs(
          currentClientLocation.areas,
          areaDescriptions,
          currentClientLocation
        );

        selectedAreas = this.getAllAreasFromClientLocations();
      } else if (client?.planningRestriction === 'NONE')
        this.showWarningMessage(
          true,
          WARNING_MESSAGE_TITLE_NO_CLIENT_LOCATION_SELECTED,
          WARNING_MESSAGE_CONTENT_NO_CLIENT_LOCATION_SELECTED,
          WarningMessageType.WARN
        );
    } else {
      if (!appendToSelection) {
        if (this.mapComponentRef.current !== null)
          this.mapComponentRef.current.markSelectedAreas(areas, false);
        areas = [] as Area[];
      }
      addAreaStubs(areas, areaDescriptions);

      selectedAreas = areas;
    }

    if (selectedAreas) {
      this.setState({ areas, selectedClientLocations }, () =>
        this.updateAreaSelection(
          selectedAreas,
          fitSelection,
          ignoreConflictingAreas
        )
      );
    }
  }

  /**
   * Add areas to the selection that were provided
   * on application start
   */
  addInitialAreas(): void {
    const { initAreas } = this.props;
    const { weekpart, client } = this.state;

    if (initAreas.length === 0) return;

    this.addAreas(
      initAreas.split(',').map(
        areaKey =>
          ({
            areaKey,
            weekpart,
            countryCode: COUNTRY_CODE_DE,
            type: client?.clientLayers[0].type ?? LAYER_TYPE_POSTCODE,
          } as AreaDescription)
      ),
      false,
      true
    );
  }

  /**
   * Adds the areas resulting from the perimeter selection
   * to a buffer. All previously selected areas that area
   * within the perimeter will be sorted out.
   * This method provides the ability to revert the perimeter
   * selection process.
   *
   * @param pPerimeterAreas
   */
  addPerimeterAreas(pPerimeterAreas: Area[]): void {
    const { clientLocationMode } = this.props;
    const { areas } = this.state;

    let selectedAreas: Area[];

    if (clientLocationMode)
      selectedAreas = this.getAllAreasFromClientLocations();
    else selectedAreas = areas;

    const perimeterAreas = pPerimeterAreas.filter(
      area =>
        !selectedAreas.find(
          selectedArea => selectedArea.areaKey === area.areaKey
        )
    );

    this.addAreas(perimeterAreas, true, true);

    this.showRevertPerimeter(true, perimeterAreas);

    setTimeout(() => {
      this.showRevertPerimeter(false, undefined);
    }, 20000);
  }

  /**
   * Adds a history item to the histrory list after the
   * user made an order or rquested an offer
   *
   * @param pHistory
   */
  addHistory(pHistory: any): void {
    const { client } = this.state;

    if (!client) return;

    let newHistory;
    if (client.transmissionType === TRANSMISSION_TYPE_ORDER)
      newHistory = extractOrderTemplate(pHistory);
    else newHistory = extractOfferTemplate(pHistory);

    const history = [...client.history, newHistory];

    client.history = sortHistoryTemplates(history);

    this.setState({ client });
  }

  /**
   * Checks if the current clientLocation has the given
   * area selected
   *
   * @param areaKey
   */
  isSelectedByCurrentClientLocation(areaKey: string): boolean {
    const { selectedClientLocations } = this.state;
    const currentClientLocation = selectedClientLocations.find(
      selectedClientLocation => selectedClientLocation.selected
    );

    if (!currentClientLocation) return false;
    const clientLocations = this.getMultiSelectionClientLocations(areaKey);

    return clientLocations.some(
      clientLocation => clientLocation.id === currentClientLocation.id
    );
  }

  /**
   * Restores the selection from an offer or order
   *
   * @param historyTemplate
   */
  async applyHistoryTemplate(
    historyTemplate: OrderHistoryTemplate | OfferHistoryTemplate
  ): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM);

    if (!client) return;

    const order = client?.transmissionType === TRANSMISSION_TYPE_ORDER;
    const historyIndex = client.history.findIndex(
      (history: HistoryTemplate) => history.id === historyTemplate.id
    );

    if (client.history[historyIndex].locations.length === 0) {
      const res = await getHistoryItemData(client, order, historyTemplate.id);
      let historyItem;

      if (!Number.isNaN(+res)) {
        // TODO error
        historyItem = {} as OrderHistoryTemplate;
      } else if (order) historyItem = res as OrderHistoryTemplate;
      else historyItem = res as OfferHistoryTemplate;

      client.history[historyIndex] = historyItem;
    }

    this.setState({ client }, () => {
      this.applyTemplate(client.history[historyIndex].locations);
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM
      );
    });
  }

  /**
   * Restores the selection from a distribution template
   *
   * @param distributionTemplate
   */
  applyDistributionTemplate(distributionTemplate: DistributionTemplate): void {
    const { locations } = distributionTemplate;
    this.setState(
      {
        selectedDistributionTemplate: locations,
      },
      () => {
        const { current } = this.mapComponentRef;
        if (current !== null) current.removeMarkedFeatures();

        this.applyTemplate(locations);
      }
    );
  }

  /**
   * Actual restore logic for templates and offers/orders
   *
   * @param distributionTemplateLocations
   */
  applyTemplate(
    distributionTemplateLocations: DistributionTemplateLocation[]
  ): void {
    const { client, weekpart } = this.state;

    if (!client) return;

    let { selectedClientLocations } = this.state;

    selectedClientLocations.forEach(
      (selectedClientLocation: ClientLocation) => {
        this.setClientLocationSelected(undefined);

        if (this.mapComponentRef.current !== null)
          this.mapComponentRef.current.markSelectedAreas(
            selectedClientLocation.areas,
            false,
            selectedClientLocation.show
          );
        selectedClientLocation.areas = [] as Area[];
      }
    );

    selectedClientLocations = [];

    forEach(distributionTemplateLocations, pLocation => {
      const clientLocation = client.clientLocations.find(
        (fClientLocation: ClientLocation) =>
          fClientLocation.id === pLocation.locationId
      );
      if (!clientLocation) return;
      clientLocation.areas = getAreaStubsFromTemplate(
        pLocation.areas,
        weekpart,
        clientLocation.id
      );

      selectedClientLocations.push(clientLocation);
    });

    this.setState({ selectedClientLocations }, () =>
      this.updateAreaSelection(this.getAllAreasFromClientLocations(), true)
    );
  }

  /**
   * If initial areas are provided this method adds
   * those areas to the current selection.
   *
   * @param data
   */
  applyInitAreas(data: InitAreasPayload): void {
    let { areas, selectedClientLocations } = this.state;
    const { client } = this.state;
    const { weekpart } = data;
    const initAreas = data.areas;

    if (!initAreas || initAreas.length === 0) return;

    let selectedAreas: Area[];

    if ((initAreas[0] as Area).areaKey) {
      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(areas, false);
      areas = initAreas as Area[];
      selectedAreas = areas;
    } else if ((initAreas[0] as ClientLocation).name && client) {
      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(
          this.getAllAreasFromClientLocations(),
          false
        );

      selectedClientLocations = [];

      forEach(
        initAreas as ClientLocation[],
        (pClientLocation: ClientLocation) => {
          const clientLocation = client.clientLocations.find(
            (fClientLocation: ClientLocation) =>
              fClientLocation.id === pClientLocation.id
          );
          if (!clientLocation) return;

          clientLocation.areas = pClientLocation.areas;

          selectedClientLocations.push(clientLocation);
        }
      );

      selectedAreas = this.getAllAreasFromClientLocations();
    }

    this.setState({ selectedClientLocations, areas, weekpart }, () =>
      this.updateAreaSelection(selectedAreas, true, true)
    );
  }

  /**
   * Restores a clientLocation ditribution template
   *
   * @param clientLocationDistributionTemplate
   */
  applyClientLocationDistributionTemplate(
    clientLocationDistributionTemplate: ClientLocationDistributionTemplate
  ): void {
    const { client, weekpart, selectedDistributionTemplate } = this.state;

    if (!client) return;

    const { clientLocations } = client;

    const fClientLocation = clientLocations.find(
      (clientLocation: ClientLocation) =>
        clientLocation.id === clientLocationDistributionTemplate.locationId
    );

    if (!fClientLocation) return;

    let nSelectedDistributionTemplate = selectedDistributionTemplate;

    if (!nSelectedDistributionTemplate) nSelectedDistributionTemplate = [];

    const existingIndex = nSelectedDistributionTemplate.findIndex(
      template =>
        template.locationId === clientLocationDistributionTemplate.locationId
    );

    if (existingIndex !== -1) {
      nSelectedDistributionTemplate = [
        ...nSelectedDistributionTemplate.slice(0, existingIndex),
        ...nSelectedDistributionTemplate.slice(existingIndex + 1),
      ];

      const clientLocation = clientLocations.find(
        location =>
          location.id === clientLocationDistributionTemplate.locationId
      );

      if (clientLocation)
        this.removeClientLocationFromSelection(clientLocation);
    }

    nSelectedDistributionTemplate = [
      ...nSelectedDistributionTemplate,
      ...[clientLocationDistributionTemplate],
    ];

    this.addClientLocationToSelection(fClientLocation);

    if (this.mapComponentRef.current !== null)
      this.mapComponentRef.current.markSelectedAreas(
        fClientLocation.areas,
        false,
        fClientLocation.show
      );
    fClientLocation.areas = [] as Area[];
    fClientLocation.areas = getAreaStubsFromTemplate(
      clientLocationDistributionTemplate.areas,
      weekpart,
      fClientLocation.id
    );

    this.setState(
      { client, selectedDistributionTemplate: nSelectedDistributionTemplate },
      () =>
        this.updateAreaSelection(this.getAllAreasFromClientLocations(), true)
    );
  }

  /**
   * Hides the selection of the given clientLocation on the map.
   * Areas won't be deselected, just hidden.
   *
   * @param clientLocation
   * @param show
   */
  hideClientLocationSelection(
    clientLocation: ClientLocation | number,
    show: boolean
  ): void {
    const { selectedClientLocations } = this.state;
    const clientLocationId = Number.isNaN(+clientLocation)
      ? (clientLocation as ClientLocation).id
      : (clientLocation as number);

    const fClientLocation = selectedClientLocations.find(
      selectedClientLocation => selectedClientLocation.id === clientLocationId
    );

    if (!fClientLocation) return;

    fClientLocation.show = show;

    this.setState(
      {
        selectedClientLocations,
      },
      () => {
        const { current } = this.mapComponentRef;
        if (current !== null) {
          current.markSelectedAreas(
            fClientLocation.areas,
            true,
            fClientLocation.show,
            fClientLocation.colorSelectedFill
          );
          this.sendMessage(
            {
              clientLocationId: fClientLocation.id,
              show: fClientLocation.show,
            } as HideClientLocationSelectionPayload,
            MessageType.MESSAGE_TYPE_HIDE_CLIENT_LOCATION_SELECTION
          );
        }
      }
    );
  }

  /**
   * Removes a given area from the current selection
   *
   * @param area
   */
  removeArea(area: Area | undefined): void {
    if (!area) return;

    const { areas, selectedClientLocations } = this.state;
    const { clientLocationMode } = this.props;
    let selectedAreas;
    let removedAreas;
    if (clientLocationMode) {
      if (!area.clientLocationId) {
        const currentClientLocation = selectedClientLocations.find(
          selectedClientLocation => selectedClientLocation.selected
        );
        if (!currentClientLocation) return;
        area.clientLocationId = currentClientLocation.id;
      }
      const clientLocation = selectedClientLocations.find(
        selectedClientLocation =>
          selectedClientLocation.id === area.clientLocationId
      );

      if (!clientLocation) return;

      removedAreas = remove(clientLocation.areas, { areaKey: area.areaKey });

      selectedAreas = this.getAllAreasFromClientLocations();
    } else {
      removedAreas = remove(areas, { areaKey: area.areaKey });
      selectedAreas = areas;
    }

    this.updateAreaSelection(selectedAreas, false, false, removedAreas);
  }

  /**
   * Removes multiple areas from the selection
   *
   * @param pAreas
   */
  removeAreas(pAreas: Area[] | undefined): void {
    if (!pAreas) return;

    const { areas, selectedClientLocations } = this.state;
    const { clientLocationMode } = this.props;
    let selectedAreas = [] as Area[];
    let removedAreas = [] as Area[];

    if (clientLocationMode) {
      pAreas.forEach(area => {
        if (!area.clientLocationId) {
          const currentClientLocation = selectedClientLocations.find(
            selectedClientLocation => selectedClientLocation.selected
          );
          if (!currentClientLocation) return;
          area.clientLocationId = currentClientLocation.id;
        }
        const clientLocation = selectedClientLocations.find(
          selectedClientLocation =>
            selectedClientLocation.id === area.clientLocationId
        );

        if (!clientLocation) return;

        removedAreas = [
          ...remove(clientLocation.areas, { areaKey: area.areaKey }),
          ...removedAreas,
        ];
      });

      selectedAreas = this.getAllAreasFromClientLocations();
    } else {
      removedAreas = remove(areas, area =>
        pAreas.find(pArea => pArea.areaKey === area.areaKey)
      );
      selectedAreas = areas;
    }

    this.updateAreaSelection(selectedAreas, false, false, removedAreas);
  }

  /**
   * When the map layer type is switched convert or remove the
   * already selected areas to the new layer type. However if
   * no new layer type is submitted do nothing.
   *
   * @param compatible
   * @param newLayerType
   */
  convertAreaLayerType(compatible: boolean, newLayerType?: LayerType): void {
    const { clientLocationMode } = this.props;
    const { applyLayerTypeToAll } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);

    if (!compatible) {
      this.removeAllAreas(false);
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);

      return;
    }

    if (!applyLayerTypeToAll) {
      const { current } = this.mapComponentRef;

      if (current === null) return;

      if (clientLocationMode) {
        const { selectedClientLocations } = this.state;

        this.findFeaturesForAreas(this.getAllAreasFromClientLocations(), true);

        this.setState({ selectedClientLocations }, () => {
          selectedClientLocations.forEach(clientLocation =>
            current.markSelectedAreas(
              clientLocation.areas,
              true,
              true,
              clientLocation.colorSelectedFill
            )
          );
        });
      } else {
        const { areas } = this.state;
        this.findFeaturesForAreas(areas);

        this.setState({ areas }, () => current.markSelectedAreas(areas, true));
      }

      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);
      return;
    }

    let nAreas = [] as Area[];

    if (newLayerType) {
      if (clientLocationMode) {
        const { selectedClientLocations } = this.state;

        selectedClientLocations.forEach(clientLocation => {
          const convertedAreas = changeAreasLayerType(
            getAreaDescriptions(clientLocation.areas),
            newLayerType
          );

          clientLocation.areas = [];

          addAreaStubs(clientLocation.areas, convertedAreas, clientLocation);
        });

        nAreas = this.getAllAreasFromClientLocations();
      } else {
        let { areas } = this.state;

        const convertedAreas = changeAreasLayerType(
          getAreaDescriptions(areas),
          newLayerType
        );
        areas = [];

        addAreaStubs(areas, convertedAreas);

        nAreas = areas;
      }
    }

    this.updateAreaSelection(nAreas, false, false);

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);
  }

  /**
   * Reverts the selection to the state before the perimeter
   * selection was performed.
   */
  revertPerimeter(): void {
    const { perimeterAreas } = this.state;

    this.removeAreas(perimeterAreas);
    this.showRevertPerimeter(false, undefined);
  }

  /**
   * Removes all areas from selection.
   *
   * Before the action is performed a confirmation
   * dialog will launch.
   */
  removeAllAreas(showWarning = true): void {
    if (showWarning)
      this.showConfirmationModal(
        true,
        CONFIRMATION_MODAL_TITLE_REMOVE_ALL_AREAS,
        CONFIRMATION_MODAL_CONTENT_REMOVE_ALL_AREAS,
        this.removeAllAreasCallback
      );
    else this.removeAllAreasCallback();
  }

  removeAllAreasCallback(): void {
    let { areas, selectedClientLocations } = this.state;
    const { clientLocationMode } = this.props;
    const { current } = this.mapComponentRef;

    if (clientLocationMode) {
      selectedClientLocations.forEach(selectedClientLocation => {
        if (current !== null)
          current.markSelectedAreas(
            selectedClientLocation.areas,
            false,
            selectedClientLocation.show
          );

        selectedClientLocation.selected = false;
        selectedClientLocation.areas = [];
      });

      selectedClientLocations = [];
    } else {
      if (current !== null) {
        current.markSelectedAreas(areas, false);
      }

      areas = [];
    }

    this.setState(
      { areas, selectedClientLocations, totalCirculation: 0 },
      this.initMessage
    );
  }

  /**
   * Deletes a distribution template.
   *
   * Before the action is performed a confirmation
   * dialog will launch.
   *
   * @param distributionTemplate
   */
  removeDistributionTemplate(distributionTemplate: DistributionTemplate): void {
    this.showConfirmationModal(
      true,
      CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE,
      CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE,
      async () => {
        this.enableLoadingOverlay(
          true,
          REQUEST_IDENTIFIER_DELETE_DISTRIBUTION_TEMPLATE
        );
        const { client } = this.state;

        if (!client) return;

        const response = await deleteDistributionTemplate(
          client,
          distributionTemplate
        );
        if (response === 204) {
          if (client) {
            remove(client.distributionTemplates, {
              id: distributionTemplate.id,
            });

            this.setState({ client });
          }
        }
        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_DELETE_DISTRIBUTION_TEMPLATE
        );
      }
    );
  }

  /**
   * Deletes a clientLocation distribution template.
   *
   * Before the action is performed a confirmation
   * dialog will launch.
   *
   * @param clientLocationDistributionTemplate
   * @param clientLocation
   */
  async removeClientLocationDistributionTemplate(
    clientLocationDistributionTemplate: ClientLocationDistributionTemplate,
    clientLocation: ClientLocation
  ): Promise<void> {
    this.showConfirmationModal(
      true,
      CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE,
      CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE,
      async () => {
        const { client } = this.state;

        this.enableLoadingOverlay(
          true,
          REQUEST_IDENTIFIER_DELETE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE
        );

        if (client) {
          const fClientLocation = client.clientLocations.find(
            location => location.id === clientLocation.id
          );

          if (fClientLocation) {
            const res = await deleteClientLocationDistributionTemplate(
              client,
              clientLocation,
              clientLocationDistributionTemplate
            );

            if (res < 300) {
              remove(fClientLocation?.clientLocationDistributionTemplates, {
                id: clientLocationDistributionTemplate.id,
              });

              this.setState({ client });
            }
          }
        }

        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_DELETE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE
        );
      }
    );
  }

  /**
   * Requests information about the selected areas from the api
   *
   * @param selectedAreas
   * @param fitSelection
   * @param ignoreConflictingAreas
   * @param removedAreas
   * @param dynamicPlaningParams
   */
  async updateAreaSelection(
    selectedAreas: Area[],
    fitSelection: boolean = false,
    ignoreConflictingAreas: boolean = false,
    removedAreas?: Area[],
    dynamicPlaningParams?: DynamicPlaningParam[]
  ): Promise<void> {
    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_AREA_DATA);
    const {
      areas,
      selectedClientLocations,
      weekpart,
      distributionWeek,
      distributionYear,
    } = this.state;
    const { clientLocationMode } = this.props;

    this.findFeaturesForAreas(selectedAreas);

    // Request area data
    const newAreas = (await getAreaData(
      getAreaDataFormat(selectedAreas),
      weekpart,
      distributionWeek,
      distributionYear,
      dynamicPlaningParams
    )) as Area[];

    const { current } = this.mapComponentRef;
    let processedAreas = [] as Area[];

    if (current !== null) {
      // If there are removed areas deselect them on the map
      if (removedAreas && removedAreas.length > 0) {
        current.markSelectedAreas(removedAreas, false);
      } else {
        // Else search for differences between currently selected on the map
        // and the actually selected areas in the apps state
        current.markAreas(
          getAdditionalAreasDifference(
            clientLocationMode ? this.getAllAreasFromClientLocations() : areas,
            newAreas
          ),
          false,
          true,
          undefined,
          true
        );
      }
    }

    if (newAreas) {
      if (clientLocationMode) {
        // Check for each clientLocation if it contains one of the areas
        selectedClientLocations.forEach(selectedClientLocation => {
          selectedClientLocation.areas = this.processNewAreas(
            selectedClientLocation.areas,
            newAreas,
            ignoreConflictingAreas
          );

          if (current !== null) {
            // Mark the newly selected areas on the map
            current.markSelectedAreas(
              selectedClientLocation.areas,
              true,
              selectedClientLocation.show,
              selectedClientLocation.colorSelectedFill
            );
          }
        });
      } else {
        // Process the new areas
        processedAreas = this.processNewAreas(
          areas,
          newAreas,
          ignoreConflictingAreas
        );
        // Mark the new areas
        if (current !== null) current.markSelectedAreas(processedAreas, true);
      }

      this.setState({ selectedClientLocations, areas: processedAreas }, () => {
        if (fitSelection) this.fitSelection();
        this.setState(
          {
            totalCirculation: this.getTotalCirculation(),
          },
          () => {
            this.initMessage();
            // this.getPrice();
          }
        );
      });
    }
    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_AREA_DATA);
  }

  /**
   * update the overlaying portals area selection
   */
  async initMessage(): Promise<void> {
    const {
      selectedClientLocations,
      areas,
      weekpart,
      /* price, */ totalCirculation,
    } = this.state;
    const { clientLocationMode } = this.props;

    const messagePayload = {} as AreaMessagePayload;
    if (clientLocationMode) messagePayload.areas = selectedClientLocations;
    else messagePayload.areas = areas;

    messagePayload.weekpart = weekpart;
    messagePayload.price = await this.getPrice();
    messagePayload.totalCirculation = totalCirculation;

    this.sendMessage(messagePayload, MessageType.MESSAGE_TYPE_UPDATE_AREAS);
  }

  /**
   * Processes the received areas to the right format
   * as well as make a view checks and adjusments
   * (e.g. matching selected and delselected localities)
   *
   * @param areas
   * @param newAreas
   * @param ignoreConflictingAreas
   */
  processNewAreas(
    areas: Area[],
    newAreas: Area[],
    ignoreConflictingAreas: boolean = false
  ): Area[] {
    const returnAreas = [] as Area[];

    newAreas.forEach(pNewArea => {
      // Check if the area is completely new or already slected
      const newArea = cloneDeep(pNewArea);
      const match = areas.find(area => area.areaKey === newArea.areaKey);

      // If the area is already selected adopt previously made changes
      if (match) {
        if (match.feature) newArea.feature = match.feature;
        if (match.clientLocationId) {
          const { clientLocationId, countryCode, type } = match;
          newArea.clientLocationId = clientLocationId;
          newArea.additionalAreas.forEach(area => {
            area.clientLocationId = clientLocationId;
          });
          newArea.countryCode = countryCode;
          newArea.type = type;
        }

        // Check if the area is selected by multiple subsidaries
        const conflictingClientLocations = this.getMultiSelectionClientLocations(
          newArea.areaKey
        );
        remove(conflictingClientLocations, { id: newArea.clientLocationId });

        newArea.localities.forEach(newLocality => {
          const oldLocality = match.localities.find(
            mLocality => mLocality.localityKey === newLocality.localityKey
          );
          if (oldLocality) newLocality.selected = oldLocality.selected;
        });

        if (match.id === -1 && conflictingClientLocations.length >= 1) {
          if (!ignoreConflictingAreas) {
            const conflictingAreas = this.getConflictingAreas(newArea);
            let hasConflicts = false;

            if (conflictingAreas.length > 0) {
              conflictingAreas.forEach(conflictingArea => {
                conflictingArea.localities.forEach(locality => {
                  const sLocality = newArea.localities.find(
                    fLocality => fLocality.localityKey === locality.localityKey
                  );
                  if (sLocality && sLocality.selected && locality.selected) {
                    sLocality.selected = false;
                    hasConflicts = true;
                  }
                });
              });

              // Split the localities between the selecting clientLocations
              if (hasConflicts) this.showLocalitiesModal(newArea, true);
            }
          }
        }

        // Calculate the circulation of the new area
        if (newArea.circulation > 0)
          newArea.circulation = calculateAreaCirculation(newArea);

        newArea.circulationTotal = calculateAreaCirculationTotal(newArea);

        // Add the new area to the array of areas
        returnAreas.push(newArea);
      }
    });

    // Add corresponding features and layers to the area
    this.findFeaturesForAreas(
      returnAreas.filter(returnArea => !returnArea.feature || !returnArea.layer)
    );

    // Add corresponding features and layers to the areas additional areas
    this.findFeaturesForAreas(
      getAllAdditionalAreas(returnAreas).filter(
        returnAdditionalArea =>
          !returnAdditionalArea.feature || !returnAdditionalArea.layer
      )
    );
    return returnAreas;
  }

  /**
   * Enables and disbales the loading spinner.
   * To enable the loading animation a request identifier has to be submitted.
   * This will be added to an array. As long as this array is not empty
   * the anomation will be visible.
   * Everytime the loading parameter is false, a entry with the value of
   * the request identifier will be removed from the array.
   *
   * @param loading
   * @param requestIdentifier
   */
  enableLoadingOverlay(loading: boolean, requestIdentifier: string): void {
    const { pendingRequests } = this.state;

    let nPendingRequests = [] as string[];

    if (loading) nPendingRequests = [requestIdentifier, ...pendingRequests];
    else {
      const index = pendingRequests.indexOf(requestIdentifier);
      if (index < 0) nPendingRequests = pendingRequests;
      else {
        nPendingRequests = [
          ...pendingRequests.slice(0, index),
          ...pendingRequests.slice(index + 1),
        ];
      }
    }
    this.setState({
      pendingRequests: nPendingRequests,
      isLoading: nPendingRequests.length > 0,
    });
  }

  /**
   * Finds and adds corresponding features and layers for an area
   *
   * @param areas
   */
  findFeaturesForAreas(areas: Area[], forceNew?: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    areas.forEach(area => {
      if (!area.feature || !area.layer || forceNew) {
        const mapLink = current.findAreaKeyOnMap(area.areaKey);
        area.feature = mapLink.feature;
        area.layer = mapLink.layer;
      }
    });
  }

  /**
   * Enter or leave fullscreen.
   *
   * @param isFullscreen
   */
  changeFullscreen(isFullscreen: boolean): void {
    this.setState({
      isFullscreen,
    });
  }

  changeApplyLayerTypeToAll(applyLayerTypeToAll: boolean): void {
    this.setState({ applyLayerTypeToAll });
  }

  /**
   * Show or hide the import modal dialog
   * @param show
   */
  showImportModal(show: boolean): void {
    this.setState({
      showImportModal: show,
    });
  }

  /**
   * Toggle the clientLocation modal dialog and sets the client location
   *
   * @param clientLocation
   */
  showClientLocationModal(clientLocation?: ClientLocation): void {
    const { showClientLocationModal } = this.state;
    const { current } = this.clientLocationModalRef;

    if (current === null) return;

    if (clientLocation) current.setClientLocation(clientLocation);

    this.setState({ showClientLocationModal: !showClientLocationModal });
  }

  /**
   * Toggle the clientLocation distribution template modal and sets the client location
   * @param clientLocation
   */
  showDistributionTemplateModal(
    clientLocation?: ClientLocation,
    distirbutionTemplate?: DistributionTemplate
  ): void {
    const { showDistributionTemplateModal } = this.state;
    const { current } = this.templateModalRef;

    if (current === null) return;

    this.setState({
      showDistributionTemplateModal: !showDistributionTemplateModal,
    });
    current.setClientLocation(clientLocation);
    current.setDistributionTemplate(distirbutionTemplate);
  }

  /**
   * Toggles the print map modal
   *
   * @param show
   */
  showPrintMapModal(): void {
    const { showPrintMapModal } = this.state;
    const { current } = this.templateModalRef;

    if (current === null) return;

    this.setState({
      showPrintMapModal: !showPrintMapModal,
    });
  }

  showLocalitiesModal(area: Area | undefined, show: boolean): void {
    const { current } = this.localitiesModalRef;

    if (current !== null && area) current.setArea(cloneDeep(area));

    this.setState({
      showLocalitiesModal: show,
    });
  }

  /**
   * Toggles the subsdiary list
   *
   * @param show
   */
  showClientLocationList(show: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showClientLocationList: show,
      showDistributionTemplateList: false,
      showHistoryList: false,
    });

    current.adjustMapSize();
  }

  /**
   * Toggles the distribution template list
   *
   * @param show
   */
  showDistributionTemplateList(show: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showDistributionTemplateList: show,
      showClientLocationList: false,
      showHistoryList: false,
    });

    current.adjustMapSize();
  }

  /**
   * Toggles the history list
   *
   * @param show
   */
  showHistoryList(show: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showHistoryList: show,
      showClientLocationList: false,
      showDistributionTemplateList: false,
    });

    current.adjustMapSize();
  }

  /**
   * Shows or hides the confimation dialog.
   * Also the title, content and a callback have to be provided.
   *
   * @param showConfirmationModal
   * @param title
   * @param content
   * @param callback
   */
  showConfirmationModal(
    showConfirmationModal: boolean,
    title: string,
    content: string,
    callback: Function
  ): void {
    this.setState({
      showConfirmationModal,
      confirmationModalTitle: title ?? undefined,
      confirmationModalContent: content ?? undefined,
      confirmationCallback: callback ?? undefined,
    });
  }

  /**
   * Shows a warning message on the top of the map.
   * Title, content, and type have to be provided.
   *
   * @param showWarningMessage
   * @param title
   * @param content
   * @param type
   */
  showWarningMessage(
    showWarningMessage: boolean,
    title?: string,
    content?: string,
    type?: WarningMessageType
  ): void {
    const { current } = this.warningMessageRef;

    if (current === null) return;

    current.setContents(title, content, type);

    this.setState({
      showWarningMessage,
    });
  }

  /**
   * Shows or hides the clientLocation distribution modal dialog
   * and sets the corresponding clientLocation
   *
   * @param show
   * @param clientLocation
   */
  showClientLocationDistributionTemplates(
    show: boolean,
    clientLocation?: ClientLocation
  ): void {
    const { current } = this.clientLocationDistributionTemplateModalRef;
    if (current === null) return;

    current.setClientLocation(clientLocation);

    this.setState({ showClientLocationDistributionTemplateModal: show });
  }

  /**
   * Shows or hides the the response modal. Also title and
   * content have to be set here.
   *
   * @param showResponseModal
   * @param title
   * @param content
   */
  showResponseModal(
    showResponseModal: boolean,
    title: string,
    content: string
  ): void {
    this.setState({
      showResponseModal,
      responseModalTitle: title ?? undefined,
      responseModalContent: content ?? undefined,
    });
  }

  /**
   * Shows or hides the isochrone/dynamic planing modal dialog
   *
   * @param showIsochroneModal
   */
  showIsochroneModal(showIsochroneModal: boolean): void {
    this.setState({
      showIsochroneModal,
    });
  }

  /**
   * Shows or hides the layer type selection
   *
   * @param showIsochroneModal
   */
  showLayerTypeSelection(showLayerTypeSelection: boolean): void {
    const { current } = this.mapContainerRef;

    if (current === null) return;

    current.showLayerTypeSelection(showLayerTypeSelection);
  }

  /**
   * Completely expand or collapses the list of clientLocations and
   * their areas in fullscreen mode.
   */
  showAllAreaItems(): void {
    const { showAllAreaItems } = this.state;

    this.setState({ showAllAreaItems: !showAllAreaItems });
  }

  /**
   * Shows or hides the button that can revert a perimeter selection.
   *
   * @param showRevertPerimeterButton
   * @param perimeterAreas
   */
  showRevertPerimeter(
    showRevertPerimeterButton: boolean,
    perimeterAreas?: Area[]
  ): void {
    this.setState({ showRevertPerimeterButton, perimeterAreas });
  }

  /**
   * Toggle the clientLocation list
   */
  toggleClientLocationList(): void {
    const { showClientLocationList } = this.state;
    this.showClientLocationList(!showClientLocationList);
  }

  /**
   * Toggle the distribution template list
   */
  toggleDistributionTemplateList(): void {
    const { showDistributionTemplateList } = this.state;
    this.showDistributionTemplateList(!showDistributionTemplateList);
  }

  /**
   * Toggle the history list
   */
  toggleHistoryList(): void {
    const { showHistoryList } = this.state;
    this.showHistoryList(!showHistoryList);
  }

  /**
   * Creates a new subsdiary
   *
   * @param clientLocation
   */
  async createClientLocation(
    clientLocation: ClientLocationSend
  ): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION);

    if (!client) return;

    const newClientLocation = await createClientLocation(
      clientLocation,
      client
    );

    if (!newClientLocation) {
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION
      );
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT()
      );
      return;
    }

    client.clientLocations.push(newClientLocation);

    const { current } = this.mapComponentRef;
    if (current !== null) current.appendLocationToMap(newClientLocation);

    this.setState({ client }, () =>
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION
      )
    );
  }

  /**
   * Callback for the distribution template modal dialog that
   * triggers the restoration of the template.
   *
   * @param templateName
   * @param clientLocation
   */
  templateModalCallback(
    templateName: string,
    clientLocation?: ClientLocation
  ): void {
    if (clientLocation)
      this.createClientLocationDistributionTemplate(
        templateName,
        clientLocation
      );
    else this.createDistributionTemplate(templateName);
  }

  /**
   * Creates a new distribution template from the current selection.
   *
   * @param distributionTemplateName
   */
  async createDistributionTemplate(
    distributionTemplateName: string
  ): Promise<void> {
    const { selectedClientLocations, client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_CREATE_DISTRIBUTION_TEMPLATE
    );

    const newDistributionTemplate = (await createDistributionTemplate(
      client,
      getDistributionTemplate(selectedClientLocations, distributionTemplateName)
    )) as DistributionTemplate;

    if (newDistributionTemplate) {
      client.distributionTemplates.push(newDistributionTemplate);
      this.setState({ client });
    } else {
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT()
      );
    }

    this.enableLoadingOverlay(
      false,
      REQUEST_IDENTIFIER_CREATE_DISTRIBUTION_TEMPLATE
    );
  }

  updateDistributionTemplate(
    distributionTemplate: DistributionTemplate,
    updateAreas?: boolean,
    clientLocation?: ClientLocation
  ): void {
    if (updateAreas)
      this.showConfirmationModal(
        true,
        CONFIRMATION_MODAL_TITLE_OVERWRITE_DISTRIBUTION_TEMPLATE,
        CONFIRMATION_MODAL_CONTENT_OVERWRITE_DISTRIBUTION_TEMPLATE,
        () =>
          this.updateDistributionTemplateCallback(
            distributionTemplate,
            distributionTemplate.name,
            clientLocation,
            updateAreas
          )
      );
    else
      this.showDistributionTemplateModal(clientLocation, distributionTemplate);
  }

  /**
   * Update a new distribution template from the current selection.
   *
   * @param distributionTemplateName
   */
  async updateDistributionTemplateCallback(
    distributionTemplate: DistributionTemplate,
    distirbutionTemplateName: string,
    clientLocation?: ClientLocation,
    updateAreas?: boolean
  ): Promise<void> {
    const { selectedClientLocations, client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_UPDATE_DISTRIBUTION_TEMPLATE
    );

    let uDistributionTemplate: DistributionTemplate;

    if (updateAreas) {
      uDistributionTemplate = getDistributionTemplate(
        selectedClientLocations,
        distirbutionTemplateName
      );
      uDistributionTemplate = {
        ...uDistributionTemplate,
        ...{ id: distributionTemplate.id },
      };
    } else
      uDistributionTemplate = {
        ...distributionTemplate,
        ...{ name: distirbutionTemplateName },
      };

    uDistributionTemplate = (await updateDistributionTemplate(
      client,
      uDistributionTemplate
    )) as DistributionTemplate;

    if (uDistributionTemplate) {
      const distributionTemplates = [...client.distributionTemplates];
      const index = distributionTemplates.findIndex(
        (template: DistributionTemplate) =>
          template.id === uDistributionTemplate.id
      );

      if (index > -1) {
        client.distributionTemplates = [
          ...distributionTemplates.slice(0, index),
          uDistributionTemplate,
          ...distributionTemplates.slice(index + 1),
        ];

        this.setState({ client });
      }
    } else {
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT()
      );
    }

    this.enableLoadingOverlay(
      false,
      REQUEST_IDENTIFIER_UPDATE_DISTRIBUTION_TEMPLATE
    );
  }

  /**
   * Enables drawing on the map
   */
  drawPerimeter(): void {
    const { current } = this.mapComponentRef;

    if (current !== null) {
      current.drawPerimeter();
    }
  }

  /**
   * Creates a new clientLocation distribution template
   *
   * @param clientLocationDistributionTemplateName
   * @param clientLocation
   */
  async createClientLocationDistributionTemplate(
    clientLocationDistributionTemplateName: string,
    clientLocation: ClientLocation
  ): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE
    );

    if (client) {
      const fClientLocation = client.clientLocations.find(
        pClientLocation => pClientLocation.id === clientLocation.id
      );

      if (fClientLocation) {
        // TODO api call
        const res = (await createClientLocationDistributionTemplate(
          client,
          clientLocation,
          getClientLocationDistributionTemplate(
            fClientLocation,
            clientLocationDistributionTemplateName
          )
        )) as ClientLocationDistributionTemplate;

        fClientLocation.clientLocationDistributionTemplates.push(res);
        this.setState({ client });
      }
    }

    this.enableLoadingOverlay(
      false,
      REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION_DISTRIBUTION_TEMPLATE
    );
  }

  /**
   * Updates a given clientLocation.
   *
   * @param clientLocation
   */
  async updateClientLocation(
    clientLocation: ClientLocationSend
  ): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION);

    if (client) {
      const updatedClientLocation = (await updateClientLocation(
        client,
        clientLocation
      )) as ClientLocation;

      if (!updatedClientLocation) return;

      const index = client.clientLocations.findIndex(
        pClientLocation => pClientLocation.id === updatedClientLocation.id
      );

      if (index < 0) {
        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION
        );
        this.showResponseModal(
          true,
          RESPONSE_MODAL_FAILURE_TITLE,
          RESPONSE_MODAL_FAILURE_CONTENT()
        );
        return;
      }

      const { current } = this.mapComponentRef;
      const {
        addressName,
        city,
        colorSelectedFill,
        housenumber,
        email,
        id,
        lat,
        lon,
        name,
        number,
        openingHours,
        phone,
        planable,
        poi,
        postcode,
        street,
      } = updatedClientLocation;

      let indexClientLocation = client.clientLocations[index];

      if (current !== null) current.removeLocationFromMap(indexClientLocation);

      indexClientLocation = {
        ...indexClientLocation,
        ...{
          addressName,
          city,
          colorSelectedFill,
          email,
          housenumber,
          id,
          lat,
          lon,
          name,
          number,
          openingHours,
          phone,
          planable,
          poi,
          postcode,
          street,
        },
      };

      if (current !== null) {
        current.markSelectedAreas(
          indexClientLocation.areas,
          true,
          indexClientLocation.show,
          indexClientLocation.colorSelectedFill
        );
        current.appendLocationToMap(indexClientLocation);
      }

      client.clientLocations[index] = indexClientLocation;

      this.setState({ client });
    }

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION);
  }

  /**
   * Triggers the export of the selection as a CSV file
   */
  exportCSV(): void {
    const { clientLocationMode } = this.props;
    const { areas, client, selectedClientLocations } = this.state;

    if (
      this.getAllAreasFromClientLocations().length === 0 &&
      areas.length === 0
    ) {
      this.showWarningMessage(
        true,
        WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
        WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
        WarningMessageType.WARN
      );

      return;
    }

    if (client && clientLocationMode)
      exportClientLocationCSV(selectedClientLocations, client.name);
    else exportAreaCSV(areas);
  }

  /**
   * Triggers the export of the selection as an Excel file
   */
  exportExcel(): void {
    const { userMail, clientLocationMode } = this.props;
    const { client, weekpart, selectedClientLocations, areas } = this.state;

    if (
      this.getAllAreasFromClientLocations().length === 0 &&
      areas.length === 0
    ) {
      this.showWarningMessage(
        true,
        WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
        WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
        WarningMessageType.WARN
      );

      return;
    }

    if (client)
      exportExcel(
        client,
        client.name,
        weekpart,
        userMail ?? '',
        clientLocationMode ? selectedClientLocations : undefined,
        clientLocationMode ? undefined : areas
      );
  }

  /**
   * Deletes a clientLocation.
   *
   * @param clientLocation
   */
  deleteClientLocation(clientLocation: ClientLocation): void {
    this.showConfirmationModal(
      true,
      CONFIRMATION_MODAL_TITLE_DELETE_CLIENT_LOCATION,
      CONFIRMATION_MODAL_CONTENT_DELETE_CLIENT_LOCATION,
      async () => {
        const { client } = this.state;

        if (!client) return;

        this.enableLoadingOverlay(
          true,
          REQUEST_IDENTIFIER_DELETE_CLIENT_LOCATION
        );

        const res = await deleteClientLocation(client, clientLocation);

        if ((res.status ?? res) < 300) {
          remove(
            client.clientLocations,
            location => location.id === clientLocation.id
          );
          this.removeClientLocationFromSelection(clientLocation);

          const { current } = this.mapComponentRef;
          if (current) current.removeLocationFromMap(clientLocation);
        }

        this.setState({ client }, () =>
          this.enableLoadingOverlay(
            false,
            REQUEST_IDENTIFIER_DELETE_CLIENT_LOCATION
          )
        );
      }
    );
  }

  /**
   * Centers the map to the users position.
   */
  async centerLocation(): Promise<void> {
    const currentMapComponent = this.mapComponentRef.current;

    if (navigator.geolocation)
      await navigator.geolocation.getCurrentPosition(
        location => {
          const lat = location.coords.latitude;
          const lon = location.coords.longitude;
          const coordinates = { lon, lat } as Coordinates;

          if (currentMapComponent !== null) {
            currentMapComponent.zoomToCoordinates(coordinates);
          }
        },
        // eslint-disable-next-line no-console
        error => console.log('error', error)
      );
  }

  /**
   * Fit the map to the extend of the selected areas
   */
  fitSelection(): void {
    const { areas, selectedClientLocations } = this.state;
    const { clientLocationMode } = this.props;

    const { current } = this.mapComponentRef;
    if (current !== null) {
      let selectedAreas;
      if (clientLocationMode && selectedClientLocations) {
        selectedAreas = this.getAllAreasFromClientLocations();
      } else if (areas) selectedAreas = areas;

      current.fitFeatures(getAllAreaFeatures(selectedAreas));
    }
  }

  /**
   *  Triggers the export of the current selection as PDF file
   */
  printSelection(paperSize: PaperSize, selectedResolution: number): void {
    const { clientLocationMode } = this.props;
    const { selectedClientLocations, areas } = this.state;
    const { current } = this.mapComponentRef;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_PRINT_MAP);

    if (current === null) return;
    if (areas || selectedClientLocations) {
      let areasToPrint;
      if (clientLocationMode && selectedClientLocations) {
        areasToPrint = this.getAllAreasFromClientLocations();
      } else areasToPrint = areas;

      if (areasToPrint.length === 0) {
        this.showWarningMessage(
          true,
          WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
          WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
          WarningMessageType.WARN
        );
        this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_PRINT_MAP);
      } else
        current.printSelection(
          getAllAreaFeatures(areasToPrint),
          paperSize,
          selectedResolution
        );
    }
  }

  /**
   * Send messages to the overlaying portal (e.g. FPP)
   *
   * @param payload
   * @param type
   */
  sendMessage(payload: MessagePayload, type: MessageType): void {
    const { parentOrigin, clientLocationMode } = this.props;
    let payloadCopy = cloneDeep(payload);

    if (
      type === MessageType.MESSAGE_TYPE_ADD_CLIENT_LOCATION ||
      type === MessageType.MESSAGE_TYPE_REMOVE_CLIENT_LOCATION
    ) {
      payloadCopy = removeNotSerializableFromClientLocation(
        payloadCopy as ClientLocation
      );
    } else if (type === MessageType.MESSAGE_TYPE_UPDATE_AREAS) {
      if (clientLocationMode)
        payloadCopy.areas = removeNotSerializableFromClientLocations(
          (payloadCopy as AreaMessagePayload).areas as ClientLocation[]
        );
      else
        payloadCopy.areas = removeNotSerializableFromAreas(
          (payloadCopy as AreaMessagePayload).areas as Area[]
        );
    } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES) {
      payloadCopy = payloadCopy as LocalityMessagePayload;

      if (payloadCopy.area) {
        (payloadCopy.area as Area).feature = undefined;
        (payloadCopy.area as Area).layer = undefined;
      } else {
        payloadCopy.areas = removeNotSerializableFromAreas(payloadCopy.areas);
      }
    } else if (type === MessageType.MESSAGE_TYPE_CHANGE_WEEKPART) {
      // TODO
    } else if (type === MessageType.MESSAGE_TYPE_UPDATE_PRICE) {
      // TODO
    } else if (
      type === MessageType.MESSAGE_TYPE_HIDE_CLIENT_LOCATION_SELECTION
    ) {
      // TODO
    } else if (type === MessageType.MESSAGE_TYPE_MAP_INFO) {
      payloadCopy = payloadCopy as MapInfoPayload;

      if (clientLocationMode)
        payloadCopy.areas = removeNotSerializableFromClientLocations(
          payloadCopy.areas as ClientLocation[]
        );
      else
        payloadCopy.areas = removeNotSerializableFromAreas(
          payloadCopy.areas as Area[]
        );
    }

    const message = { payload: payloadCopy, type } as Message;

    if (parentOrigin !== '') window.parent.postMessage(message, parentOrigin);
  }

  /**
   * Toggles the weekpart according to the order in the array
   */
  toggleWeekpart(): void {
    const { weekpart, client } = this.state;
    const { clientLocationMode } = this.props;

    const weekparts = client?.weekparts ?? WEEKPART_ARRAY;
    const currentIndex = (weekparts?.indexOf(weekpart) ?? -1) + 1;
    const newWeekpart =
      weekparts[currentIndex === -1 ? 0 : currentIndex % weekparts.length];

    this.setState(
      {
        weekpart: newWeekpart,
      },
      () => {
        const { areas } = this.state;

        let selectedAreas;
        if (clientLocationMode)
          selectedAreas = this.getAllAreasFromClientLocations();
        else selectedAreas = areas;
        this.updateAreaSelection(selectedAreas);
      }
    );
  }

  /**
   * Sets the focus of the map to a given clientLocation
   *
   * @param clientLocation
   */
  zoomToClientLocation(clientLocation: ClientLocation): void {
    const currentMapComponent = this.mapComponentRef.current;
    if (currentMapComponent !== null) {
      const { lon, lat } = clientLocation;
      currentMapComponent.zoomToCoordinates({ lat, lon });
    }
  }

  /**
   * Sets the focus of the map to a given address. This
   * method is mainly used by the address search.
   *
   * @param address
   */
  zoomToAddress(address: Address): void {
    const currentMapComponent = this.mapComponentRef.current;
    if (currentMapComponent !== null) {
      const { lon, lat } = address.coordinates;
      currentMapComponent.zoomToCoordinates({ lat, lon });
    }
  }

  // TODO Error dialog (pdf creation, requests, ...)
  render(): JSX.Element {
    const {
      client,
      totalCirculation,
      areas,
      selectedClientLocations,
      isFullscreen,
      isLoading,
      selectedDistributionTemplate,
      lockSelection,
      showClientLocationList,
      showDistributionTemplateList,
      showHistoryList,
      showImportModal,
      showDistributionTemplateModal,
      showClientLocationModal,
      showLocalitiesModal,
      showConfirmationModal,
      showClientLocationDistributionTemplateModal,
      showIsochroneModal,
      showWarningMessage,
      showPrintMapModal,
      showRevertPerimeterButton,
      confirmationModalTitle,
      confirmationModalContent,
      confirmationCallback,
      showResponseModal,
      responseModalTitle,
      responseModalContent,
      weekpart,
      price,
      showAllAreaItems,
      applyLayerTypeToAll,
    } = this.state;
    const { clientLocationMode } = this.props;

    return (
      <div className="h-100 w-100">
        <Row
          ref={this.fullScreenContainerRef}
          id="mapFullscreenContainer"
          className="h-100 w-100 no-gutters"
        >
          {isLoading && (
            <LoadingOverlay
              loadingTitle={LOADING_PLEASE_WAIT}
              loadingSubtitle={LOADING_PROCESS_REQUEST}
            />
          )}
          <WarningMessage
            ref={this.warningMessageRef}
            show={showWarningMessage}
            closeMessage={this.showWarningMessage}
          />
          <ConfirmationModal
            callback={confirmationCallback}
            container={this.fullScreenContainerRef}
            content={confirmationModalContent}
            show={showConfirmationModal}
            title={confirmationModalTitle}
            showModal={this.showConfirmationModal}
          />
          <ResponseModal
            container={this.fullScreenContainerRef}
            content={responseModalContent}
            show={showResponseModal}
            title={responseModalTitle}
            showModal={this.showResponseModal}
          />
          <ClientLocationModal
            ref={this.clientLocationModalRef}
            container={this.fullScreenContainerRef}
            show={showClientLocationModal}
            newClientLocation={this.createClientLocation}
            showModal={this.showClientLocationModal}
            updateSusidiary={this.updateClientLocation}
            enableLoadingOverlay={this.enableLoadingOverlay}
          />
          <ImportModal
            client={client}
            container={this.fullScreenContainerRef}
            show={showImportModal}
            weekpart={weekpart}
            addAreas={this.addAreas}
            showModal={this.showImportModal}
          />
          <LocalitiesModal
            ref={this.localitiesModalRef}
            container={this.fullScreenContainerRef}
            show={showLocalitiesModal}
            weekpart={weekpart}
            confirmLocalitySelection={this.setLocalitiesSelection}
            showModal={this.showLocalitiesModal}
          />
          <PrintMapModal
            container={this.fullScreenContainerRef}
            show={showPrintMapModal}
            showModal={this.showPrintMapModal}
            printMap={this.printSelection}
          />
          <DistributionTemplateModal
            ref={this.templateModalRef}
            container={this.fullScreenContainerRef}
            show={showDistributionTemplateModal}
            newDistributionTemplate={this.templateModalCallback}
            updateistributionTemplate={this.updateDistributionTemplateCallback}
            showModal={this.showDistributionTemplateModal}
          />
          <ClientLocationDistributionTemplateModal
            ref={this.clientLocationDistributionTemplateModalRef}
            container={this.fullScreenContainerRef}
            planningRestriction={client?.planningRestriction ?? 'NONE'}
            show={showClientLocationDistributionTemplateModal}
            applyClientLocationDistributionTemplate={
              this.applyClientLocationDistributionTemplate
            }
            removeClientLocationDistributionTemplate={
              this.removeClientLocationDistributionTemplate
            }
            showModal={this.showClientLocationDistributionTemplates}
          />
          <IsochroneModal
            container={this.fullScreenContainerRef}
            show={showIsochroneModal}
            dynamicPlaningParams={DUMMY_DYN_PARAMS}
            getIsochrone={this.getIsochrone}
            showModal={this.showIsochroneModal}
          />
          {client?.clientLocations && (
            <ClientLocationListContainer
              planningRestriction={client?.planningRestriction ?? 'NONE'}
              showClientLocationList={showClientLocationList}
              clientLocations={client.clientLocations.filter(
                clientLocation => clientLocation.planable
              )}
              applyClientLocationDistributionTemplate={
                this.applyClientLocationDistributionTemplate
              }
              changeClientLocationColor={this.updateClientLocation}
              deleteClientLocation={this.deleteClientLocation}
              hideClientLocationList={this.showClientLocationList}
              setClientLocationSelected={this.setClientLocationSelected}
              showNewClientLocationDialog={this.showClientLocationModal}
              showClientLocationDistributionTemplates={
                this.showClientLocationDistributionTemplates
              }
              showClientLocationDistributionTemplateModal={
                this.showDistributionTemplateModal
              }
              zoomToClientLocation={this.zoomToClientLocation}
            />
          )}
          {client?.distributionTemplates && (
            <DistributionTemplateListContainer
              distributionTemplates={client.distributionTemplates}
              planningRestriction={client?.planningRestriction ?? 'NONE'}
              showDistributionTemplates={showDistributionTemplateList}
              applyDistributionTemplate={this.applyDistributionTemplate}
              updateDistributionTemplate={this.updateDistributionTemplate}
              deleteDistributionTemplate={this.removeDistributionTemplate}
              hideDistributionTemplateList={this.showDistributionTemplateList}
              showNewDistributionTemplateModal={
                this.showDistributionTemplateModal
              }
            />
          )}
          {client?.history && (
            <HistoryContainer
              historyTemplates={client.history}
              showHistoryTemplates={showHistoryList}
              transmissionType={client.transmissionType}
              applyHistoryTemplate={this.applyHistoryTemplate}
              hideHistoryList={this.showHistoryList}
            />
          )}
          <Col className="p-0 h-100">
            <MapMenu
              sideMenuExpanded={
                showClientLocationList ||
                showDistributionTemplateList ||
                showHistoryList
              }
              layerTypes={client?.layerTypes}
              planningRestriction={client?.planningRestriction ?? 'NONE'}
              weekpart={weekpart}
              transmissionType={client?.transmissionType}
              clientLocationMode={clientLocationMode}
              showClientLocationList={showClientLocationList}
              showDistributionTemplateList={showDistributionTemplateList}
              showHistoryList={showHistoryList}
              weekparts={client?.weekparts ?? WEEKPART_ARRAY}
              showRevertPerimeterButton={showRevertPerimeterButton}
              centerLocation={this.centerLocation}
              drawPerimeter={this.drawPerimeter}
              exportCSV={this.exportCSV}
              exportExcel={this.exportExcel}
              fitSelection={this.fitSelection}
              printSelection={this.showPrintMapModal}
              revertPerimeter={this.revertPerimeter}
              showImportModal={this.showImportModal}
              showIsochroneModal={this.showIsochroneModal}
              toggleDistributionTemplates={this.toggleDistributionTemplateList}
              toggleHistory={this.toggleHistoryList}
              toggleClientLocations={this.toggleClientLocationList}
              toggleWeekpart={this.toggleWeekpart}
              zoomToAddress={this.zoomToAddress}
              showLayerTypeSelection={this.showLayerTypeSelection}
            />
            <MapContainer
              ref={this.mapContainerRef}
              mapRef={this.mapComponentRef}
              client={client}
              lockSelection={lockSelection}
              clientLocationMode={clientLocationMode}
              weekpart={weekpart}
              selectedDistributionTemplate={selectedDistributionTemplate}
              applyToAll={applyLayerTypeToAll}
              changeAreaLayerType={this.convertAreaLayerType}
              addRemoveAreaRestrictedPlanning={
                this.addRemoveAreaRestrictedPlanning
              }
              addArea={this.addArea}
              addInitAreas={this.addInitialAreas}
              addPerimeterAreas={this.addPerimeterAreas}
              changeFullscreen={this.changeFullscreen}
              enableLoadingOverlay={this.enableLoadingOverlay}
              getDynamicAreas={this.getDynamicAreas}
              getMultiSelectionClientLocations={
                this.getMultiSelectionClientLocations
              }
              isSelectedByCurrentClientLocation={
                this.isSelectedByCurrentClientLocation
              }
              removeArea={this.removeArea}
              selectClientLocation={this.setClientLocationSelected}
              changeApplyToAll={this.changeApplyLayerTypeToAll}
              showConfimationModal={this.showConfirmationModal}
            />
          </Col>
          {isFullscreen && (
            <Col
              md={3}
              className="p-0 area-list-section h-100 d-flex flex-column"
            >
              <Row className="no-gutters px-2 pt-4">
                <Col>
                  <AreaListHeader
                    showAll={showAllAreaItems}
                    showCollapseAll={clientLocationMode}
                    collapseAll={this.showAllAreaItems}
                    removeAll={this.removeAllAreas}
                  />
                </Col>
              </Row>
              <Row className="no-gutters flex-grow-1 area-list-container px-2 pb-2">
                <Col md={12} className="h-100">
                  {clientLocationMode ? (
                    <ClientLocationAreaList
                      showAll={showAllAreaItems}
                      clientLocations={selectedClientLocations}
                      weekpart={weekpart}
                      hideClientLocationSelection={
                        this.hideClientLocationSelection
                      }
                      removeArea={this.removeArea}
                      removeClientLocation={
                        this.removeClientLocationFromSelection
                      }
                      showLocalities={this.showLocalitiesModal}
                    />
                  ) : (
                    <AreaList
                      show
                      areas={areas}
                      removeArea={this.removeArea}
                      showLocalities={this.showLocalitiesModal}
                      weekpart={weekpart}
                    />
                  )}
                </Col>
              </Row>
              <Row className="no-gutters p-2">
                <Col md={12}>
                  {(client?.showPrice || config.general.showPrice) && (
                    <PriceItem price={price} />
                  )}
                </Col>
              </Row>
              <Row className="no-gutters p-2">
                <Col md={12}>
                  <CirculationItem
                    weekpart={weekpart}
                    circulation={totalCirculation}
                    totalCirculation
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default App;
