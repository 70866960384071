import * as React from 'react';

import { Modal, Col, Button } from 'react-bootstrap';

import { BUTTON_TITLE_CONFIRM } from '../../constants/labels';

import { ResponseModalProps } from '../../@types/Modal.d';

/**
 * Modal dialog to show success or failure of a long running job.
 *
 * @param props
 */
const ResponseModal: React.FC<ResponseModalProps> = (
  props: ResponseModalProps
) => {
  const { show, showModal, title, content, container } = props;

  const onHide = (): void => {
    showModal(false, undefined, undefined);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      container={container.current}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Col className="p-0">
          <Button className="ci modal-footer-button" onClick={onHide}>
            {BUTTON_TITLE_CONFIRM}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default ResponseModal;
