import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { WarningMessageType } from '../../constants/constants';

import { WarningMessageProps, WarningMessageState } from '../../@types/App.d';

/**
 * Component to display a waring message on the upper side of the map.
 * The content and style (WARNING, ERROR, INFO, ...) has to be provided
 * via props.
 *
 * @param props
 */
export default class WarningMessage extends React.Component<
  WarningMessageProps,
  WarningMessageState
> {
  constructor(props: WarningMessageProps) {
    super(props);

    this.state = {};

    this.onClickClose = this.onClickClose.bind(this);
    this.onKeyPressClose = this.onKeyPressClose.bind(this);
    this.onHide = this.onHide.bind(this);

    this.setContents = this.setContents.bind(this);
  }

  /**
   * Click action to hide the warning.
   *
   * @param event
   */
  onClickClose(event: React.MouseEvent): void {
    event.stopPropagation();

    this.onHide();
  }

  /**
   * key press action to hide the warning.
   *
   * @param event
   */
  onKeyPressClose(event: React.KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (event.keyCode !== 13) return;

    this.onHide();
  }

  onHide(): void {
    const { closeMessage } = this.props;

    this.setState({
      title: undefined,
      content: undefined,
      type: undefined,
    });

    closeMessage(false);
  }

  /**
   * Sets the contents of the warning message
   *
   * @param title
   * @param content
   * @param type
   */
  setContents(
    title?: string,
    content?: string,
    type?: WarningMessageType
  ): void {
    this.setState({ title, content, type });
  }

  render(): JSX.Element {
    const { show } = this.props;
    const { type, title, content } = this.state;

    return (
      <div
        className={`warning-message-container ${
          show ? 'show ' : 'hidden '
        }${type}`}
      >
        <div
          className="warning-message-icon hover-icon"
          role="button"
          tabIndex={0}
          onClick={this.onClickClose}
          onKeyUp={this.onKeyPressClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="warning-message-title">{title}</div>
        <div className="warning-message-content">{content}</div>
      </div>
    );
  }
}
